import {Link} from 'react-router-dom';
import axios from 'axios';
import {useState} from 'react';

function LoginContent() {

    if (localStorage.getItem("admintokenID")!=null) {
        window.location.href="/";
    }

    let [username,setUsername]=useState("");
    let [password,setPassword]=useState("");

    let usernameerr="Required Username";
    let passworderr="Required Password";

    let [iserrusername,setIsrrusername]=useState(false);
    let [iserrpassword,setIsrrpassword]=useState(false);
    
    let [msg,setMsg]=useState("");
    let [alert,setAlert]=useState(false);
    let [msgalert,setMsgalert]=useState("");

    let [isbtn,setIsbtn]=useState(false);

    async function adminlogin() {
        var flag=1;

        if (username.length==0 || username.trim()=="") {
            // setUsernameerr("Required Username");
            flag=0;
            setIsrrusername(true);
        }
        if (password.length==0 || password.trim()=="") {
            // setPassworderr("Required Password");
            flag=0;
            setIsrrpassword(true);
        }

        if (flag==1) {

            // var fd=new FormData();
            // fd.append("loginid",loginid);
            // fd.append("password",password);
            // const requestOptions = {
            //     method: 'POST',
            //     mode: 'no-cors',
            //     body: fd,
            //     headers: { 
            //     'Accept' : 'application/json',
            //     'Content-Type' : 'application/x-www-form-urlencoded',
            //     'Access-Control-Allow-Origin' : '*',
            //     'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
            //     'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization'
            //     },
            // };
            // const response = await fetch('https://safalata.in/safalatalara/api/adminlogin', requestOptions);
            // const data = await response.json();
            // console.log(data.atoken);



            var fd=new FormData();
            fd.append("username",username);
            fd.append("password",password);
            var resp=await axios.post("https://safalata.in/safalatalara/api/adminlogin",fd);
            var data=resp.data;
      
            if (data.msgcode==1) {
                
                localStorage.setItem("admintokenID",data.atoken);
                
                var tokenval=localStorage.getItem("admintokenID");
                if (tokenval!=null) {
                        var resp=await fetch("https://safalata.in/safalatalara/api/admintoken", {
                        headers: {
                        "Content-Type":"application/json",
                        "Authorization":"Bearer "+tokenval
                        }
                    });
                    var data=await resp.json();
                    if (data.role_id==1) {
                        localStorage.removeItem("admintokenID");
                        setAlert(true);
                        setMsg("Access Denied");
                        setMsgalert("alert alert-danger text-center");
                    } else {
                        localStorage.setItem("adminname",data.name);
                        setIsbtn(true);
                        setAlert(true);
                        setMsg(data.msg);
                        setMsgalert(data.alert);

                        setTimeout(()=>{
                            window.location.href="/";
                        },2000);
                    }
                }

            } else {
                setAlert(true);
                setMsg(data.msg);
                setMsgalert(data.alert);
            }
        }
    }

    return(
        <>
                    <div className="container" style={{marginTop: 100}}>
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header"><h3 className="text-center font-weight-light my-4">SAFALATA</h3></div>
                                    <div className="card-body">
                                    {alert?<div className={msgalert} style={{textAlign:'center'}}>{msg}</div>:''}
                                            <div className="form-floating mb-3">
                                                <input className="form-control" id="inputUsername" type="text" onChange={(ev)=>{
                                                    setUsername(ev.target.value);
                                                    if (username.length>0) {
                                                        setIsrrusername(false);
                                                    }
                                                }} placeholder="Enter Username" />
                                                <label for="inputUsername">Username</label>
                                                {iserrusername?<div className="text text-danger">{usernameerr}</div>:''}
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input className="form-control" id="inputPassword" type="password" onChange={(ev)=>{
                                                    setPassword(ev.target.value);
                                                    if (password.length>0) {
                                                        setIsrrpassword(false);
                                                    }
                                                }} placeholder="Enter Password" />
                                                <label for="inputPassword">Password</label>
                                                {iserrpassword?<div className="text text-danger">{passworderr}</div>:''}
                                            </div>
                                            
                                            <div className="text-center">
                                                <button className="btn btn-primary" disabled={isbtn} style={{fontSize: 'large'}} onClick={adminlogin}>Login</button>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </>
    );
}
export default LoginContent;