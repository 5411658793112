import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

// import $ from "jquery";

function LiveExamBatchGroupDailyContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '15px',
        fontWeight: 700
    };

    const formgrouptitle={
        margin: '15px',
        fontWeight: 700,
        fontSize: 'large'
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'small'
    };

    const smalltxtblod={
        fontWeight: '700',
        fontSize: 'x-small'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'small'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'small'
    };

    const txtblue={
        color: '#0e007a',
        fontWeight: '700',
        fontSize: 'small'
    };

    const selectright={
        paddingRight: '10px'
    };


    let [exambatchlistarr, setExambatchlistarr]=useState([]);

    // let [loader,setLoader]=useState(true);

    // async function getExambatchlist() {
    //     var resp=await axios.get("https://safalata.in/safalatalara/api/liveexambatchlist");
    //     var data=resp.data;
    //     setExambatchlistarr(data);
    // }



    let [liveexamlistarr, setLiveexamlistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    // let [isnewbtn,setIsnewbtn]=useState(true);
    // let [liveexamsetname,setLiveexamsetname]=useState("");

    // let [subjectslno,setSubjectslno]=useState("");
    let [liveexamsetid,setLiveexamsetid]=useState("");


    let [isselectbatch,setIsselectbatch]=useState(true);

    let [batchlistarr,setBatchlistarr]=useState("");

    let [timeap, setTimeap]=useState("");

    // For Modal
    let [liveexamname,setLiveexamname]=useState("");
    let [liveexamid,setLiveexamid]=useState("");
    let [liveexamschedule,setLiveexamschedule]=useState("NEW EXAM");
    let [livestartschedule,setLivestartschedule]=useState("");
    let [liveendschedule,setLiveendschedule]=useState("");

    let [livestartendschedule,setLivestartendschedule]=useState("");

    let [qidcount,setQidcount]=useState("");
    let [modal,setModal]=useState("");
    let [currentshuffle,setCurrentshuffle]=useState("");
    let [newshuffle,setNewshuffle]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    let [ismodalbtn,setIsmodalbtn]=useState(true);
    // For Modal
    
    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");

    let [totalcount, setTotalcount]=useState("");
    

    let limit = 50;

    async function getLiveexamselectedlist() {
        // setLoader(true);
        var resp=await axios.get("https://safalata.in/safalatalara/api/liveexamselectedlistbydaily");
        var data=resp.data;
        setLiveexamlistarr(data);
        //setLoader(false);
    }

    async function getLiveexambatchgroup() {
        // var fd=new FormData();
        // fd.append("pageno",pageno);
        // fd.append("limit",limit);
        // var resp=await axios.post("https://safalata.in/safalatalara/api/getliveexambatchgroup",fd);
        var resp=await axios.get("https://safalata.in/safalatalara/api/getliveexambatchgroupdaily");
        var data=resp.data;
        setBatchlistarr(data);

        var resp2=await axios.get("https://safalata.in/safalatalara/api/getliveexambatchgroupdailycount");
        var data2=resp2.data;

        setTotalcount(data2);

        const total=data2;
        setPageCount(Math.ceil(total/limit));
        
        setLoader(false);
    }

    async function getLiveexambatchlist() {
        var fd=new FormData();
        fd.append("livesetslno",liveexamsetid);
        var resp=await axios.post("https://safalata.in/safalatalara/api/liveexambatchlist",fd);
        var data=resp.data;
        setExambatchlistarr(data);
    }

    // const handlePageClick = async (event) => {
    //     let page=event.selected+1;
    //     setLoader(true);
    //     var fd=new FormData();
    //     fd.append("pageno",page);
    //     fd.append("limit",limit);
    //     var resp=await axios.post("https://safalata.in/safalatalara/api/getliveexambatchgroup",fd);
    //     var data=resp.data;
    //     setBatchlistarr(data);
    //     setLoader(false);
    // };

    useEffect(()=>{
        getLiveexamselectedlist();
        getLiveexambatchgroup();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">LIVE EXAM FOR BATCH GROUP WITH SCHEDULE (DAILY)</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className='row'>
                    <div className="mt-4 col-6">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setLiveexamsetid(ev.target.value);
                                if (ev.target.value.length>0) {
                                    
                                    setIsselectbatch(false);
                                    var fd=new FormData();
                                    fd.append("livesetslno",ev.target.value);
                                    var resp=await axios.post("https://safalata.in/safalatalara/api/liveexambatchlist",fd);
                                    var data=resp.data;
                                    setExambatchlistarr(data);
                                } else {
                                    setIsselectbatch(true);
                                    setExambatchlistarr([]);
                                }                                        
                            }}>
                            <option value="">---------SELECT LIVE EXAM SET---------</option>

                            {liveexamlistarr.map((le)=>
                            <option key={le.liveset_slno} value={le.liveset_slno}>{le.liveset_name} (EXAM ID - {le.liveset_slno})</option>
                            )}

                            </select>
                        </div>
                    </div>
                    <div className="mt-4 col-6">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async (ev)=>{
                                
                                if (ev.target.value.length>0) {
                                    if(window.confirm("Confirm Batch Alloted?")) {
                                        var fd=new FormData();

                                        fd.append("liveexamsetid",liveexamsetid);
                                        fd.append("batchslno",ev.target.value);
                                        var resp=await axios.post("https://safalata.in/safalatalara/api/setbatchliveexam",fd);
                                        var data=resp.data;
                                        getLiveexambatchlist();
                                        getLiveexambatchgroup();
                                    }
                                }
                            }}>
                            <option value="">---------SELECT BATCH---------</option>

                            {exambatchlistarr.map((e)=>
                                <option key={e.batch_slno} value={e.batch_slno}>{e.batch_name}</option>                             
                            )}

                            </select>
                        </div>
                    </div>

                    
                    
                </div>
                

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    BATCH GROUP FOR LIVE EXAM [TOTAL - {totalcount}] ::  (DAILY)
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '10%'}}>GROUP ID</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '10%'}}>EXAM ID</th>
                                    <th style={{fontWeight: 700, fontSize: 'small', width: '18%'}}>EXAM NAME</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '10%'}}>BATCH</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '18%'}}>EXAM DATE TIME</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '10%'}}>STATUS</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '10%'}}>SHUFFLE</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '14%'}}>ACTION</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                            
                            {batchlistarr.map((e)=>
                                <tr key={e.liveset_slno}>
                                    <td className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '10%'}}>{e.liveexamset_serial_no}</td>
                                    <td className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '10%'}}>{e.liveset_slno}</td>
                                    <td style={{fontWeight: 700, fontSize: 'small', width: '18%'}}>{e.liveset_name}</td>
                                    <td className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '10%'}}>
                                    {e.batchobj.map((o)=>
                                        <span key={o.batch_slno}>{o.batch_name} <Link to="" onClick={async ()=>{
                                            var fd=new FormData();
                                            fd.append("liveexamsetid",e.liveset_slno);
                                            fd.append("batchslno",o.batch_slno);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/removebatchliveexam",fd);
                                            var data=resp.data;
                                            getLiveexambatchgroup();

                                            if(liveexamsetid.length > 0) {
                                                getLiveexambatchlist();
                                            }
                                            
                                        }}><i class="fas fa-times" style={{fontWeight: 500, color: 'red', fontSize: 'small'}}></i></Link><br/></span>
                                    )}
                                    </td>
                                    <td className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '18%'}}>
                                        {e.liveexamsetselection_start_schedule!=null? dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy HH:MM")+'-'+dateFormat(e.liveexamsetselection_end_schedule, "HH:MM TT") : ''}
                                    </td>
                                    {e.liveexamsetselection_status==1?
                                    <td className='text-center' style={txtgreen}>EXAM SOON</td>
                                    :e.liveexamsetselection_status==2?<td className='text-center' style={txtblue}>EXAM OPEN</td>
                                    :<td className='text-center' style={{fontWeight: 700, color: 'red', fontSize: 'small', width: '10%'}}>EXAM CLOSED</td>}
                                    {e.liveexamsetselection_shuffle==0?
                                    <td className='text-center' style={{fontWeight: 700, color: 'red', fontSize: 'small', width: '10%'}}>NON SHUFFLE</td>
                                    :e.liveexamsetselection_shuffle==1?
                                    <td className='text-center' style={{fontWeight: 700, color: 'green', fontSize: 'small', width: '10%'}}>FULL SHUFFLE</td>
                                    :
                                    <td className='text-center' style={{fontWeight: 700, color: 'blue', fontSize: 'small', width: '10%'}}>PARTIAL SHUFFLE</td>
                                    }

                                    <td className='text-center' style={{fontWeight: 700, fontSize: 'small', width: '10%'}}>
                                    <button className="btn btn-default" style={{fontSize: 'large', marginRight: '5px'}} onClick={()=>{
                                        setModal(1);
                                        openModal();
                                        setLiveexamid(e.liveset_slno);
                                        setLiveexamname(e.liveset_name);
                                        setLiveexamschedule(e.liveexamsetselection_start_schedule?dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy HH:MM")+'-'+dateFormat(e.liveexamsetselection_end_schedule, "HH:MM TT"):'');
                                    }}><i className='fas fa-clock'></i></button>
                                    
                                    <button className="btn btn-default" style={{fontSize: 'large'}} onClick={async ()=>{
                                        setModal(2);
                                        openModal();
                                        setLiveexamid(e.liveset_slno);
                                        setLiveexamname(e.liveset_name);
                                        setQidcount(e.qidcount);
                                        setCurrentshuffle(e.liveexamsetselection_shuffle);
                                        setIsmodalbtn(true);
                                        setIsmodalalert(false);
                                        
                                    }}><i class="fas fa-random"></i></button>

                                   
                                    
                                    </td>
                                </tr>
                                
                            )}
                            

                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}

                </div>

            </div>

            {/* <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div> */}

        </div>


        {/* For Modal */}
        {modal==1?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group text-center" style={formgrouptitle}>
                :::LIVE EXAM SCHEDULE:::
            </div>
            <div className="form-group" style={formgroup}>
                <label>EXAM NAME : {liveexamname}</label>
            </div>
            <div className="form-group" style={formgroup}>
                <label>EXISTING SCHEDULE : {liveexamschedule}</label>
            </div>
            <div className="form-group" style={formgroup}>
                <label>START &amp; END SCHEDULE :</label>
                <input type="date" className='form-control' onChange={(ev)=>{
                    setLivestartendschedule(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsmodalbtn(false);
                    } else {
                        setIsmodalbtn(true);
                    }
                }}/>
            </div>
            {/* <div className="form-group" style={formgroup}>
                <label>START SCHEDULE :</label>
                <input type="datetime-local" className='form-control' onChange={(ev)=>{
                    setLivestartschedule(ev.target.value);
                    if (liveendschedule.length>0 && ev.target.value.length>0) {
                        setIsmodalbtn(false);
                    } else {
                        setIsmodalbtn(true);
                    }
                }}/>
            </div> */}
            {/* <div className="form-group" style={formgroup}>
                <label>END SCHEDULE :</label>
                <input type="datetime-local" className='form-control' onChange={(ev)=>{
                    setLiveendschedule(ev.target.value);
                    if (livestartschedule.length>0 && ev.target.value.length>0) {
                        setIsmodalbtn(false);
                    } else {
                        setIsmodalbtn(true);
                    }
                }}/>
            </div> */}
            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("livesetid",liveexamid);
                // fd.append("livestartschedule",livestartschedule);
                // fd.append("liveendschedule",liveendschedule);
                fd.append("livestartendschedule",livestartendschedule);
                // var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamscheduleupdate",fd);
                var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamscheduleupdatedaily",fd);
                var data=resp.data;
                setIsmodalalert(true);
                setIsmodalbtn(true);

                setTimeout(()=>{
                    setIsOpen(false);
                    getLiveexambatchgroup();
                }, 100)
                
                                
            }} className="btn btn-primary" disabled={ismodalbtn}>Save Changes</button>
                {ismodalalert?<div className='text text-success'>Schedule Update</div>:''}
            </div>
        </Modal>
        :
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group text-center" style={formgrouptitle}>
                :::LIVE EXAM MODAL:::
            </div>
            <div className="form-group" style={formgroup}>
                <label style={{fontSize:'large',paddingTop:'5px'}}>EXAM NAME : {liveexamname}</label>
            </div>
            <div className="form-group" style={formgroup}>
                <label style={{fontSize:'large',paddingTop:'5px'}}>EXISTING SHUFFLE : 
                {currentshuffle==0?
                    ' Non Shuffle'
                : currentshuffle==1?
                    ' Full Shuffle'
                :
                    ' Partial Shuffle'
                }
                </label>
            </div>
            <div className="form-group" style={formgroup}>
                <label style={{fontSize:'large',paddingTop:'5px'}}>UPDATE SHUFFLE : </label>
                    <div class="form-check" style={{fontSize:'large',paddingTop:'5px'}}>
                        <input class="form-check-input" type="radio" name="radio" value="0" onChange={(ev)=>{
                            setNewshuffle(ev.target.value);
                            if (ev.target.value.length>0) {
                                setIsmodalbtn(false);
                            } else {
                                setIsmodalbtn(true);
                            }
                        }} />
                        <label class="form-check-label">
                        Non Shuffle
                        </label>
                    </div>
                    <div class="form-check" style={{fontSize:'large'}}>
                        <input class="form-check-input" type="radio" name="radio" value="1" onChange={(ev)=>{
                            setNewshuffle(ev.target.value);
                            if (ev.target.value.length>0) {
                                setIsmodalbtn(false);
                            } else {
                                setIsmodalbtn(true);
                            }
                        }} />
                        <label class="form-check-label">
                        Full Shuffle
                        </label>
                    </div>
                {qidcount==100?
                    <div class="form-check" style={{fontSize:'large'}}>
                        <input class="form-check-input" type="radio" name="radio" value="2" onChange={(ev)=>{
                            setNewshuffle(ev.target.value);
                            if (ev.target.value.length>0) {
                                setIsmodalbtn(false);
                            } else {
                                setIsmodalbtn(true);
                            }
                        }} />
                        <label class="form-check-label">
                        Partial Shuffle
                        </label>
                    </div>
                :''}
            </div>

            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("livesetid",liveexamid);
                fd.append("newshuffle",newshuffle);
                var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamshuffleupdate",fd);
                var data=resp.data;
                setIsmodalalert(true);
                setIsmodalbtn(true);

                setTimeout(()=>{
                    setIsOpen(false);
                    getLiveexambatchgroup();
                }, 100)
                
                                
            }} className="btn btn-primary" disabled={ismodalbtn}>Save Changes</button>
                {ismodalalert?<div className='text text-success'>Schedule Update</div>:''}
            </div>
        </Modal>
        }
        {/* FOr Modal */}
        </>
    );

}

export default LiveExamBatchGroupDailyContent;