import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader


//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

import {useNavigate} from "react-router-dom";

function SQLTablePartitionContent() {

    let navigate = useNavigate();

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: '1rem'
    };

    const txtblodcenter={
        fontWeight: '700',
        fontSize: 'medium',
        textAlign: 'center'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.4)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconkey={
        color: '#d49b00',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrophy={
        color: '#0004d4',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrash={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const div70={
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700'
    };

    const txtgrey={
        color: '#787878',
        fontWeight: '700'
    };

    const picstyle={
        width: '40px'
    };
    
    const picstylelarge={
        width: '300px'
    };


    let [isnewbtn,setIsnewbtn]=useState(true);
    // let [content,setContent]=useState("");
    // let [statuscode,setStatuscode]=useState("");


    // let [pageno,setPageno]=useState("");

    let [load,setLoad]=useState(false);
    
    let [partitionvalue,setPartitionvalue]=useState("");
    let [partitionname,setPartitionname]=useState("PLEASE SELECT ABOVE");
    let [sqlcontent,setSqlcontent]=useState("");

    async function getSQLStatement() {
        var fd=new FormData();
        fd.append("partitionvalue",partitionvalue);
        var resp=await axios.post("https://safalata.in/safalatalara/api/fetchsqldata",fd);
        var data=resp.data;

        setSqlcontent(data.content);

        // axios({
        //     method: "POST",
        //     url: "https://safalata.in/safalatalara/api/fetchsqldata/",
        //     data: fd,
        // }
        // ).then((response) => {
        //     console.log("=======>",response.data);
        //     setSqlcontent(response.data.content);
        // },
        //     (error) => {
        //       console.log(error);
        //     }
        // );
    }
    

    async function putSQLStatement() {
        setLoad(true);
        var fd=new FormData();
        fd.append("partitionvalue",partitionvalue);
        fd.append("sqlcontent",sqlcontent);
        // alert(partitionvalue);
        var resp=await axios.post("https://safalata.in/safalatalara/api/savesqldata",fd);
        var data=resp.data;

        setLoad(false);

        alert(data.message);
        // setSqlcontent("");

        // setSqlcontent(data.content);
    }

    async function executeSQLPartition() {
        setIsOpen(true);
        var fd=new FormData();
        fd.append("partitionvalue",partitionvalue);
        fd.append("sqlcontent",sqlcontent);
        // alert(partitionvalue);
        var resp=await axios.post("https://safalata.in/safalatalara/api/executesqlpartition",fd);
        var data=resp.data;
        setIsOpen(false);
        alert(data.message);
    }

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">DB SQL Table Statement</h2>

            <div className='container' style={batchbg}>
                <div className="mt-4 input-group" style={div70}>
                    
                    <select className="form-control" onChange={async (ev)=>{
                        if (ev.target.value.length>0) {
                            var index = ev.nativeEvent.target.selectedIndex;
                            setPartitionvalue(ev.target.value);
                            setPartitionname(ev.nativeEvent.target[index].text);
                            setIsnewbtn(false);
                            
                        } else if (ev.target.value=="") {
                            setPartitionvalue("");
                            setPartitionname("PLEASE SELECT ABOVE");
                            setIsnewbtn(true);
                            
                        }
                    }} >
                        <option value="">----------Select Practice DB Statement For Patitioning--------</option>
                        {/* <option value="exam_practice_qst">{"Exam Practice Question Table"}</option>
                        <option value="exam_practice_answer">{"Exam Practice Answer Table"}</option> */}
                        <option value="exam_practice_qst_answer">{"Exam Practice Question Answer Table"}</option>
                        <option value="studpracticeexamsheet">{"Stud Practice Exam Sheet Table"}</option>
                        {/* <option value="practiceexamsetselection">{"Practice Exam Set Selection Table"}</option> */}
                        {/* <option value="practiceexamset">{"Practice Exam Set Table"}</option>                         */}
                        {/* <option value="studpracticeexamscore">{"Stud Practice Exam Score Table"}</option> */}
                    </select>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getSQLStatement}>GET SQL STATEMENT</button>
                        
                    </span>
                </div>

            </div>

            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    DETAILS FOR SQL TABLE PARTITION :: {partitionname}
                    
                </div>
                <div className="card-body">

                <div className="mt-4 form-group">
                    <textarea className="form-control small" rows={20} placeholder='SHOW CREATE SQL TABLE STATEMENT' onChange={(ev)=>{
                        setSqlcontent(ev.target.value);
                    }} defaultValue={sqlcontent}/>
                </div>
                <div className="mt-4 form-group text-center">
                    <button className="mx-2 btn btn-info" type="button" onClick={putSQLStatement} disabled={isnewbtn}>SAVE SQL QUERY</button>
                    {/* <button className="mx-5 btn btn-danger" type="button" disabled={isnewbtn} onClick={executeSQLPartition}>EXECUTE SQL PARTITION</button> */}
                </div>
                
                </div>
            </div>

            {/* <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div> */}

            

        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <div className="main">
                <div className="loaderWrapper">
                    LOADING..... PLEASE WAIT
                </div>
            </div>                        

        </Modal>
        {/* FOr Modal */}


        
        </>
    );

}

export default SQLTablePartitionContent;