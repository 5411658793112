import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader


//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

import {useNavigate} from "react-router-dom";

function InactiveStudentContent() {

    let navigate = useNavigate();

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: '1rem'
    };

    const txtblodcenter={
        fontWeight: '700',
        fontSize: 'medium',
        textAlign: 'center'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.4)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconkey={
        color: '#d49b00',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrophy={
        color: '#0004d4',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrash={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const div70={
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700'
    };

    const txtgrey={
        color: '#787878',
        fontWeight: '700'
    };

    const picstyle={
        width: '40px'
    };
    
    const picstylelarge={
        width: '300px'
    };

    // For Modal Check
    let [modalid,setModalid]=useState(1);
    // For Modal Check

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [search,setSearch]=useState("");

    let [batchlistarr, setBatchlistarr]=useState([]);
    let [batchslno, setBatchslno]=useState(0);

    let [name,setName]=useState("");
    let [id,setId]=useState("");
    let [status,setStatus]=useState("");
    let [batchnm,setBatchnm]=useState("");

    let [changestatus, setChangestatus]=useState("");
    let [newpassword, setNewpassword]=useState("");
    let [ispassworderr, setIspassworderr]=useState(false);
    
    let [count,setCount]=useState("");

    // let [pageno,setPageno]=useState("");
    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");
    let [studlistarr, setStudlistarr]=useState([]);
    let [loader,setLoader]=useState(false);
    
    let [pic,setPic]=useState("");

    let [batchlist, setBatchlist]=useState([]);

    let [batchname,setBatchname]=useState("");

    //let [limit,setLimit]=useState(3);

    let limit = 200;

    async function getStudlist() {
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("search",search);
        fd.append("limit",limit);
        //alert(pageno);
        var resp=await axios.post("https://safalata.in/safalatalara/api/getinactivestudlist",fd);
        var data=resp.data;
        setStudlistarr(data.obj);
        setCount(data.datacount);
        // setBatchlist(data.batchlist);

        setBatchname(data.batchname);
        console.log(data.batchname);

        const total=data.datacount;
        setPageCount(Math.ceil(total/limit));
        
        setLoader(false);
    }

    async function getBatchlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/newactivebatchlist");
        var data=resp.data;
        setBatchlistarr(data);
        
    }

    const handlePageClick = async (event) => {
        let page=event.selected+1;
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",page);
        fd.append("search",search);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.in/safalatalara/api/getactivestudlist",fd);
        var data=resp.data;
        setStudlistarr(data.obj);
        setCount(data.datacount);
        setBatchlist(data.batchlist);
        setLoader(false);
    };



    useEffect(()=>{
        // getStudlist();
        // setPageno(1);
        getBatchlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">IN-ACTIVE STUDENT</h2>

            <div className='container' style={batchbg}>
                <div className="mt-4 input-group" style={div70}>
                    
                    <select className="form-control" onChange={async (ev)=>{
                        if (ev.target.value.length>0) {
                            setSearch(ev.target.value);
                            setIsnewbtn(false);
                            
                        } else if (ev.target.value=="") {
                            setIsnewbtn(true);
                            // setLoader(true);
                            // var fd=new FormData();
                            // fd.append("pageno",pageno);
                            // fd.append("search","");
                            // fd.append("limit",limit);
                            // var resp=await axios.post("https://safalata.in/safalatalara/api/getinactivestudlist",fd);
                            // var data=resp.data;
                            // setStudlistarr(data.obj);
                            // setCount(data.datacount);
                            // setBatchlist(data.batchlist);

                            // const total=data.datacount;

                            // setPageCount(Math.ceil(total/limit));
                            // setLoader(false);
                        }
                    }} >
                        <option value="">-------------------------Select Batch-------------------------</option>
                        {batchlistarr.map((e)=>
                            <option value={e.batch_slno} key={e.batch_slno}>{e.batch_name}</option>
                        )}
                    </select>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getStudlist}>SEARCH CANDIDATES</button>
                    </span>
                </div>

            </div>

            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    LAST 3 MONTHS IN-ACTIVE STUDENT LIST - TOTAL IN-ACTIVE STUDENTS ( {count} )  :: BATCH - {batchname}
                    &nbsp;&nbsp;&nbsp;&nbsp;{count>0? <a className='text-primary' role="button" onClick={()=>{
                        if (search.length==0) {
                            alert('Download PDF After Search Batch..');
                        } else {
                            var path=`/viewinactivestudent/`+search;
                            navigate(path);
                        }
                        
                    }}>View PDF</a>:''}
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={{fontWeight: '700',fontSize: '0.8rem', width: '5%'}} className='text-center'>#</th>
                                    <th style={{fontWeight: '700',fontSize: '0.8rem', width: '10%'}}>Pic</th>
                                    <th style={{fontWeight: '700',fontSize: '0.8rem', width: '20%'}}>Name</th>
                                    {/* <th style={txtblod}>DOB</th> */}
                                    <th style={{fontWeight: '700',fontSize: '0.8rem', width: '10%'}}>Mobile</th>
                                    <th style={{fontWeight: '700',fontSize: '0.8rem', width: '10%'}} className='text-center'>Enroll No</th>
                                    <th style={{fontWeight: '700',fontSize: '0.8rem', width: '10%'}} className='text-center'>Enroll Date</th>
                                    <th style={{fontWeight: '700',fontSize: '0.8rem', width: '15%'}}>City</th>
                                    {/* <th style={{fontWeight: '700',fontSize: '0.8rem', width: '10%'}}>Batch</th> */}
                                    <th style={{fontWeight: '700',fontSize: '0.8rem', width: '20%'}} className='text-center'>Payment List</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {count>0?
                            
                            studlistarr.map((e,i=1)=>
                                e.enroll_id!=null?
                                <tr key={e.id}>
                                    <td style={{fontWeight: '700',fontSize: '0.75rem', width: '5%'}} className='text-center'>{++i}</td>
                                    <td style={{fontWeight: '700',fontSize: '0.75rem', width: '10%'}} onClick={()=>{
                                        openModal();
                                        setModalid(4);
                                        setPic(e.pic);                                        
                                        }}><img src={"https://safalata.in/safalatalara/uploadpic/"+e.pic} role="button" style={picstyle}/></td>
                                    <td style={{fontWeight: '700',fontSize: '0.8rem', width: '20%'}}>{e.name}</td>
                                    <td style={{fontWeight: '700',fontSize: '0.8rem', width: '10%'}}>{e.phone}</td>
                                    <td style={{fontWeight: '700',fontSize: '0.75rem', width: '10%'}} className='text-center'>{e.enroll_id}</td>
                                    <td style={{fontWeight: '700',fontSize: '0.75rem', width: '10%'}} className='text-center'>{e.enroll_date}</td>
                                    <td style={{fontWeight: '700',fontSize: '0.75rem', width: '15%'}}>{e.city}</td>
                                    {/* {e.city.length>20?e.city.substr(0,20)+'..':e.city.length>0?e.city:''} */}
                                    <td style={{fontWeight: '700',fontSize: '0.75rem', width: '20%'}}>{e.paylist!=null?e.paylist:''}</td>
                          
                                    
                                </tr>
                                :
                                ''
                            )
                            // <tr><td style={{fontWeight: '700',fontSize: '0.75rem', width: '5%'}} className='text-center' colspan="8"> Data Found</td></tr>
                        :
                            <tr><td style={{fontWeight: '700',fontSize: '0.75rem', width: '5%'}} className='text-center' colspan="8">No Data Found</td></tr>
                        }
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            {/* <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div> */}

            

        </div>


        {/* For Modal */}
        {modalid==1?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT BATCH ALTERATION:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT BATCH - {batchnm}
            </div>
            
            <div className="form-group" style={formgroup}>
                <label>Change Batch - </label>
                    <select value={batchslno} className="form-control" onChange={(ev)=>{
                        setBatchslno(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                        } else {
                            setIsnewbtn(true);
                        }
                    }} >
                        <option value="">-------------------------Select Batch-------------------------</option>
                        {batchlistarr.map((e)=>
                            <option value={e.batch_slno} key={e.batch_slno}>{e.batch_name}</option>
                        )}
                    </select>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("id",id);
                fd.append("batchslno",batchslno);
                var resp=await axios.post("https://safalata.in/safalatalara/api/updatestudstatus",fd);
                var data=resp.data;
                setIsOpen(false);
                //batchwisefetchlist();
                getStudlist();                
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}
        {modalid==2?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT STATUS:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT STATUS - {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            
            <div className="form-group" style={formgroup}>
                <select value={changestatus} className="form-control" onChange={(ev)=>{
                    setChangestatus(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}>
                    <option value="">----Select Status----</option>
                    <option value="0">Pending</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                    <option value="3">Blocked</option>
                </select>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("id",id);
                fd.append("status",changestatus);
                var resp=await axios.post("https://safalata.in/safalatalara/api/updatestudstatus",fd);
                var data=resp.data;
                setIsOpen(false);       
                getStudlist()         
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}
        {modalid==3?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PASSWORD CHANGE:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setNewpassword(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                    setIspassworderr(false);
                }} placeholder='Atleast 6 characters password'/>
                {ispassworderr?<div className='text text-danger'>Atleast 6 length password required!</div>:''}
                    
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var flag=1;

                if (newpassword.length<6) {
                    setIspassworderr(true);
                    flag=0;
                }

                if (flag==1) {
                    var fd=new FormData();
                    fd.append("id",id);
                    fd.append("newpassword",newpassword);
                    var resp=await axios.post("https://safalata.in/safalatalara/api/updatestudstatus",fd);
                    var data=resp.data;
                    setIsOpen(false);       
                    getStudlist();
                }
                         
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}

        {modalid==4?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PIC:::
            </div>
            <div className='form-group' style={formgroup}>
            <img src={"https://safalata.in/safalatalara/uploadpic/"+pic} style={picstylelarge}/>
            </div>
            
            
            <div className="form-group text-center" style={formgroup}>
            <button onClick={()=>{
                    setIsOpen(false);
            }} className="btn btn-danger">Close</button>
            </div>
            
        </Modal>
        :''}

        {/* FOr Modal */}
        </>
    );

}

export default InactiveStudentContent;