import Nav from "../include/Nav";
import Sidenav from "../include/Sidenav";
import Footer from "../include/Footer";

import ClosedLiveExamListContent from "../content/ClosedLiveExamListContent";

function ClosedLiveExamList() {

    return(
    <>
    <body className="sb-nav-fixed">
        <Nav/>
        <div id="layoutSidenav">
            <Sidenav/>
            <div id="layoutSidenav_content">
                <main>
                <ClosedLiveExamListContent/>
                </main>
                <Footer/>
            </div>
        </div>
    </body>
    </>
    );
}

export default ClosedLiveExamList;