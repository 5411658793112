import Nav from "../include/Nav";
import Sidenav from "../include/Sidenav";
import Footer from "../include/Footer";

import ViewInactiveStudentContent from "../content/ViewInactiveStudentContent";

function ViewInactiveStudent() {

    return(
    <>
    <body className="sb-nav-fixed">
        

        <main>
                <ViewInactiveStudentContent/>
                </main>
    </body>
    </>
    );
}

export default ViewInactiveStudent;