//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
// import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
import { trim } from 'jquery';
// For Facebook Loader

function AddSuccessStoryContent() {

    

    // For Modal

    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [storylistarr, setStorylistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    let [count, setCount]=useState("");

    async function getsuccessstorylist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/successstorylist");
        var data=resp.data;
        setStorylistarr(data.obj);
        setCount(data.count);
        setLoader(false);
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");
    let [msgcolor,setMsgcolor]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);

    let [name,setName]=useState("");
    let [city,setCity]=useState("");
    let [designation,setDesignation]=useState("");
    let [department,setDepartment]=useState("");
    let [image,setImage]=useState(null);

    let [nameerr,setNameerr]=useState(false);
    let [cityerr,setCityerr]=useState(false);
    let [designationerr,setDesignationerr]=useState(false);
    let [departmenterr,setDepartmenterr]=useState(false);
    let [imageerr,setImageerr]=useState(null);
       
    async function addsuccessstory() {
        setIsalert(false);
        var flag=1;

        if (name.length==0 || name.trim()=="") {
            setNameerr(true);
            flag=0;
        }
        if (city.length==0 || city.trim()=="") {
            setCityerr(true);
            flag=0;
        }
        if (designation.length==0 || designation.trim()=="") {
            setDesignationerr(true);
            flag=0;
        }
        if (department.length==0 || department.trim()=="") {
            setDepartmenterr(true);
            flag=0;
        }
        if (image==null) {
            setImageerr(true);
            flag=0;
        }

        if (flag==1) {
            var fd=new FormData();
            fd.append("name",name);
            fd.append("city",city);
            fd.append("designation",designation);
            fd.append("department",department);
            fd.append("image",image);
            var resp=await axios.post("https://safalata.in/safalatalara/api/addsuccessstory",fd);
            var data=resp.data;

            setIsalert(true);
            setMsgcolor(data.msgcolor);
            setMsg(data.msg);

            getsuccessstorylist();
            setName("");
            setCity("");
            setDesignation("");
            setDepartment("");
            setImage(null);
            setIsnewbtn(true);
        }
        
    }

    useEffect(()=>{
        getsuccessstorylist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">Success Story</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className={msgcolor}>{msg}</div>:''}
                <div className="mt-4 form-group">
                    <input type="text" className="form-control" placeholder='Name' onChange={(ev)=>{
                        setName(ev.target.value);
                        if (ev.target.value.length>0) {
                            setNameerr(false);
                        }
                    }} value={name}/>
                    {nameerr? <div className='text text-danger'>Name Required</div>:''}
                </div>

                <div className="mt-4 form-group">
                    <input type="text" className="form-control" placeholder='City' onChange={(ev)=>{
                        setCity(ev.target.value);
                        if (ev.target.value.length>0) {
                            setCityerr(false);
                        }
                    }} value={city}/>
                    {cityerr? <div className='text text-danger'>City Required</div>:''}
                </div>

                <div className="mt-4 form-group">
                    <input type="text" className="form-control" placeholder='Designation' onChange={(ev)=>{
                        setDesignation(ev.target.value);
                        if (ev.target.value.length>0) {
                            setDesignationerr(false);
                        }
                    }} value={designation}/>
                    {designationerr? <div className='text text-danger'>Designation Required</div>:''}
                </div>

                <div className="mt-4 form-group">
                    <input type="text" className="form-control" placeholder='Department' onChange={(ev)=>{
                        setDepartment(ev.target.value);
                        if (ev.target.value.length>0) {
                            setDepartmenterr(false);
                        }
                    }} value={department}/>
                    {departmenterr? <div className='text text-danger'>Department Required</div>:''}
                </div>

                <div className="mt-4 form-group">
                    <input type="file" className="form-control" onChange={(ev)=>{
                        setImage(ev.target.files[0]);
                        // if (ev.target.value.length>0) {
                        if (ev.target.files[0]!=null) {
                            setImageerr(false);
                        }
                    }} accept=".jpg,.jpeg"/>
                    {imageerr? <div className='text text-danger'>Image Required</div>:''}
                </div>


                <div className="mt-4 form-group text-center">
                    <button className="btn btn-info" type="button" onClick={addsuccessstory}>ADD SUCCESS STORY</button>
                </div>

            </div>


                

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    SUCCESS STORY LIST - TOTAL COUNT ( {count} )
                </div>
                <div className="card-body">
                    
                {!loader?

                        <div className='row'>
                            {storylistarr.map((n)=>
                            <div className='col-md-4' style={{marginTop: 10, marginBottom: 10, border: '1px solid #999'}}>
                                <button className='btn btn-default' onClick={async ()=>{
                                    var fd=new FormData();
                                    fd.append("id",n.id);
                                    var resp=await axios.post("https://safalata.in/safalatalara/api/deletesuccessstory",fd);
                                    var data=resp.data;
                                    getsuccessstorylist();
                                }} style={{float: 'right'}}><i className='fas fa-times' style={{color: '#ff0000'}}></i></button>
                              <span style={{margin: 0, paddingTop: 5, paddingBottom:5}}><img src={"https://safalata.in/safalatalara/uploadpic/"+n.image} style={{width: '200px'}}/><br/>
                              {n.name+ ', '+n.city}<br/>
                              {n.designation+ ', '+n.department}<br/></span>
                              
                            </div>
                            )}
                        </div>

                        
                :<TableLoader/>}
                </div>
            </div>
        </div>


        
        </>
    );

}

export default AddSuccessStoryContent;