import Nav from "../include/Nav";
import Sidenav from "../include/Sidenav";
import Footer from "../include/Footer";

import ViewActiveStudentContent from "../content/ViewActiveStudentContent";

function ViewActiveStudent() {

    return(
    <>
    <body className="sb-nav-fixed">
        

        <main>
                <ViewActiveStudentContent/>
                </main>
    </body>
    </>
    );
}

export default ViewActiveStudent;