//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

import $ from "jquery";

function AddLiveExamSetContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const selectright={
        paddingRight: '10px'
    };


    let [sublistarr, setSublistarr]=useState([]);

    // let [loader,setLoader]=useState(true);

    async function getSubjectlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/managecourselist");
        var data=resp.data;
        setSublistarr(data);
    }



    let [liveexamlistarr, setLiveexamlistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    let [count, setCount]=useState(0);

    async function getLivesetlist() {
        //var resp=await axios.get("https://safalata.in/safalatalara/api/newlivesetlist");
        var resp=await axios.get("https://safalata.in/safalatalara/api/liveexamsetlist");
        var data=resp.data;
        setLiveexamlistarr(data.obj);
        //setCount(data.datacount);
        setLoader(false);
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [liveexamsetname,setLiveexamsetname]=useState("");

    let [subjectslno,setSubjectslno]=useState("");
    let [liveexamsetid,setLiveexamsetid]=useState("");

    let [qalistarr,setQAlistarr]=useState([]);
    
    let [qidlist, setQidlist]=useState([]);

    let [qidcount,setQidcount]=useState(0);

    // For Modal
    let [liveexamname,setLiveexamname]=useState("");
    let [liveexamid,setLiveexamid]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal

    async function getQAlistsubjectwise() {
        if (subjectslno.length>0 && liveexamsetid.length>0) {
            setLoader(true);
            var fd=new FormData();
            fd.append("subjectslno",subjectslno);
            var resp=await axios.post("https://safalata.in/safalatalara/api/getqalistsubjectwise",fd);
            var data=resp.data;
            //setQAlistarr(data);
            setQAlistarr(data.obj);
            setQidcount(data.count);

            setLoader(false);
        }
    }

    useEffect(()=>{
        getLivesetlist();
        getSubjectlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">NEW LIVE EXAM SET</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className='row'>
                    <div className="mt-4 col-6">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={(ev)=>{
                                setLiveexamsetid(ev.target.value);  
                                //alert(ev.target.value);
                                //getQAlistsubjectwise();
                                if (ev.target.value.length>0) {
                                    setIsnewbtn(false);
                                } else {
                                    setIsnewbtn(true);
                                }
                                        
                            }}>
                            <option value="">---------SELECT LIVE EXAM SET---------</option>

                            {liveexamlistarr.map((le)=>
                            <option key={le.liveset_slno} value={le.liveset_slno}>{le.liveset_name}</option>
                            )}

                            </select>
                        </div>
                    </div>
                    <div className="mt-4 col-6">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async (ev)=>{
                                setSubjectslno(ev.target.value);
                                //getQAlistsubjectwise();
                                if (ev.target.value.length>0) {
                                    setLoader(true);
                                    var fd=new FormData();
                                    fd.append("subjectslno",ev.target.value);
                                    var resp=await axios.post("https://safalata.in/safalatalara/api/getqalistsubjectwise",fd);
                                    var data=resp.data;
                                    //setQAlistarr(data);
                                    setQAlistarr(data.obj);
                                    setQidcount(data.count);

                                    setLoader(false);
                                } else {
                                    setQAlistarr([]);
                                }                    
                                
                            }}>
                            <option value="">---------SELECT SUBJECT---------</option>

                            {sublistarr.map((c)=>
                            <optgroup label={c.course_name} key={c.course_slno}>
                                {c.sub.map((s)=>
                                <option key={s.subject_slno} value={s.subject_slno}>&#8594; {s.subjectname}</option>
                                )}
                            </optgroup>                                
                            )}

                            </select>
                        </div>
                    </div>

                    
                    
                </div>
                

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    QUESTION ANSWER LIST - TOTAL COUNT ( {qidcount} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>QID</th>
                                    <th style={txtblod}>QUESTION</th>
                                    <th className='text-center' style={txtblod}>CREATED DATE</th>
                                    <th className='text-center' style={txtblod}>STATUS</th>
                                    <th className='text-center' style={txtblod}>ALLOTMENT</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                            
                            {qalistarr.map((e)=>
                                <tr key={e.q_slno}>
                                    <td className='text-center' style={txtblod}>{e.qid}</td>
                                    <td style={txtblod}>{e.question}</td>
                                    <td className='text-center' style={txtblod}>{dateFormat(e.created_at, "dd-mm-yyyy")}</td>
                                    {e.q_status==1?
                                    <td className='text-center' style={txtgreen}>ACTIVE</td>
                                    :<td className='text-center' style={txtred}>INACTIVE</td>}

                                    <td className='text-center'>
                                    <input type="checkbox" name="chkallot" value={e.qid} class="form-check-input" onChange={(ev)=>{
                                        //this.setState({checked: !this.state.checked});
                                        console.log(e.qid);
                                        
                                    }} defaultChecked/>
                                    </td>
                                </tr>
                                
                            )}
                            

                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}

                </div>

                
            </div>

            <div className="mt-4 col-12 text-center">
                <input type="button" className="btn btn-primary" value="IMPORT TO EXAM SET" onClick={async()=>{
                    var chk_value = [];
                    
                    $("input[name='chkallot']:checked").each(function() {
                        chk_value.push(this.value);
                    });
                    var numberOfchkallot = $("input[name='chkallot']:checked").length; //$('input:checkbox:checked').length;
                    
                    // alert('Subject Slno: '+subjectslno);
                    // alert('Live Exam Set ID: '+liveexamsetid);
                    // alert(chk_value);
                    // alert(numberOfchkallot);
                    // setLoader(true);

                    // alert(chk_value);
                    // alert(numberOfchkallot);
                    var fd=new FormData();
                    fd.append("subjectslno",subjectslno);
                    fd.append("liveexamsetid",liveexamsetid);
                    fd.append("chk_value",chk_value);
                    fd.append("total_chk",numberOfchkallot);
                    var resp=await axios.post("https://safalata.in/safalatalara/api/setqidliveexam",fd);
                    var data=resp.data;
                    getQAlistsubjectwise();
                    getLivesetlist();

                }} disabled={isnewbtn}/>
            </div>
        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::LIVE EXAM MODIFICATION:::
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setLiveexamname(ev.target.value);
                }} value={liveexamname}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("livesetid",liveexamid);
                fd.append("livesetname",liveexamname);
                var resp=await axios.post("https://safalata.in/safalatalara/api/livesetnameupdate",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsOpen(false);
                    getLivesetlist();
                } else {
                    setIsmodalalert(true);
                    setModalmsg("Live Exam Set Already Exist!!")
                }
                                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default AddLiveExamSetContent;