import {Link} from 'react-router-dom';

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//React Pagination
// import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

//import DateCountDown from 'react-date-countdown-timer';

function PracticeExamScoreContent() {

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconpending={
        color: '#900000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'small'
    };

    let [practicesetscorearr, setPracticesetscorearr]=useState([]);
    let [loader,setLoader]=useState(false);

    let [countscore, setCountscore]=useState(0);

    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");

    let limit = 50;

    async function getPracticesetscorelist() {
        console.log(pageno);
        console.log(limit);
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.in/safalatalara/api/getpracticesetscorelist",fd);
        var data=resp.data;
        setPracticesetscorearr(data.obj);
        setCountscore(data.datascorecount);

        const total=data.datascorecount;
        setPageCount(Math.ceil(total/limit));

        setLoader(false);
    }
      
    const handlePageClick = async (event) => {
        let page=event.selected+1;
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",page);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.in/safalatalara/api/getpracticesetscorelist",fd);
        var data=resp.data;
        setPracticesetscorearr(data.obj);
        setCountscore(data.datascorecount);

        setLoader(false);
    };

    useEffect(()=>{

        getPracticesetscorelist();

    },[])



    return(
        <>
        <div className="container-fluid px-4">
            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    PRACTICE EXAM SCORE LIST - TOTAL PRACTICE EXAM SCORE ( {countscore} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>Practice Exam ID</th>
                                    <th style={txtblod}>Practice Exam Name</th>
                                    <th className='text-center' style={txtblod}>Total Marks</th>
                                    <th className='text-center' style={txtblod}>Total Score</th>
                                    <th className='text-center' style={txtblod}>Exam Schedule</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {practicesetscorearr.map((e)=>
                            
                            <tr key={e.practiceset_slno}>
                                
                                <td className='text-center' style={txtblod}>{e.practiceset_slno}</td>
                                <td style={txtblod}>{e.practiceset_name}</td>
                                <td className='text-center' style={txtblod}>{e.total_qst}</td>

                                <td className='text-center' style={txtblod}>{e.total_mark}</td>
                                <td className='text-center' style={txtred}>{dateFormat(e.practiceexamsetaddition_start_schedule, "dd-mm-yyyy")}<br/>{dateFormat(e.practiceexamsetaddition_start_schedule, "HH:MM")}-{dateFormat(e.practiceexamsetaddition_end_schedule, "HH:MM TT")}</td>
                                <td className='text-center'>
                                    {/* <Link to={"/practicescoreview/"+e.score_unique_id+"/"+e.practiceset_slno}><button className="btn btn-primary" style={{marginRight: '5px'}}>View</button></Link> */}
                                    <Link to={"/practicescoreview/"+dateFormat(e.practiceexamsetaddition_start_schedule, "yyyy-mm-dd")+"/"+e.practiceset_slno}><button className="btn btn-primary" style={{marginRight: '5px'}}>View</button></Link>                                  
                                    
                                </td>
                            </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            // pageCount={20}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            // disabledClassName={"pagination__link--disabled"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>



        </div>

        </>
    );

}

export default PracticeExamScoreContent;