import Nav from "../include/Nav";
import Sidenav from "../include/Sidenav";
import Footer from "../include/Footer";

import ViewFullInactiveStudentContent from "../content/ViewFullInactiveStudentContent";

function ViewFullInactiveStudent() {

    return(
    <>
    <body className="sb-nav-fixed">
        

        <main>
                <ViewFullInactiveStudentContent/>
                </main>
    </body>
    </>
    );
}

export default ViewFullInactiveStudent;