//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

function NewLiveExamContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    // let [pageno,setPageno]=useState("");
    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");
    let limit = 100;
    //let [limit,setLimit]=useState(3);

    

    let [liveexamlistarr, setLiveexamlistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    let [count, setCount]=useState("");

    async function getLivesetlist() {
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.in/safalatalara/api/newlivesetlist",fd);
        var data=resp.data;
        setLiveexamlistarr(data.obj);
        setCount(data.datacount);

        const total=data.datacount;
        setPageCount(Math.ceil(total/limit));

        setLoader(false);
        
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [newliveexamname,setNewliveexamname]=useState("");

    // For Modal
    let [liveexamname,setLiveexamname]=useState("");
    let [liveexamid,setLiveexamid]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal
       
    async function addNewliveexam() {
        var fd=new FormData();
        fd.append("newliveexamname",newliveexamname);
        var resp=await axios.post("https://safalata.in/safalatalara/api/addnewliveset",fd);
        var data=resp.data;
        if (data.addalert==1) {
            getLivesetlist();
            setIsnewbtn(true);
            setNewliveexamname("");
            setIsalert(false);
        } else if (data.addalert==2) {
            setIsalert(true);
            setMsg(data.msg);
        }
        
    }

    const handlePageClick = async (event) => {
        let page=event.selected+1;
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",page);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.in/safalatalara/api/newlivesetlist",fd);
        var data=resp.data;
        setLiveexamlistarr(data.obj);
        setCount(data.datacount);
        setLoader(false);
    };

    useEffect(()=>{
        getLivesetlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">NEW LIVE EXAM SET</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className="mt-4 input-group">
                    <input type="text" className="form-control" placeholder='New Live Exam Set Name' onChange={(ev)=>{
                        setNewliveexamname(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                            //addNewbatch();
                        } else {
                            setIsnewbtn(true);
                        }
                    }} value={newliveexamname}/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={addNewliveexam}>ADD NEW LIVE EXAM</button>
                    </span>
                </div>

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    LIVE EXAM SET LIST - TOTAL COUNT ( {count} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>Live Set ID</th>
                                    <th style={txtblod}>Live Set Name</th>
                                    {/* <th className='text-center' style={txtblod}>Created Date</th> */}
                                    <th className='text-center' style={txtblod}>Live Set Status</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {liveexamlistarr.map((e)=>
                                <tr key={e.course_slno}>
                                <td className='text-center' style={txtblod}>{e.liveset_slno}</td>
                                <td style={txtblod}>{e.liveset_name}</td>
                                {/* <td className='text-center' style={txtblod}>{dateFormat(e.created_at, "dd-mm-yyyy")}</td> */}

                                {e.liveset_status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}

                                <td className='text-center'><button onClick={async ()=>{
                                        openModal();
                                        setLiveexamid(e.liveset_slno);
                                        setLiveexamname(e.liveset_name);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button>

                                    {e.liveset_status==1?<button onClick={async ()=>{
                                        if (window.confirm("Do you want to inactive this live set?")) {
                                            var fd=new FormData();
                                            fd.append("livesetid",e.liveset_slno);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/livesetactiveinactive",fd);
                                            var data=resp.data;
                                            getLivesetlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    :
                                    <button onClick={async ()=>{
                                        if (window.confirm("Do you want to active this live set?")) {
                                            var fd=new FormData();
                                            fd.append("livesetid",e.liveset_slno);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/livesetactiveinactive",fd);
                                            var data=resp.data;
                                            getLivesetlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    }

                                    <button onClick={async ()=>{
                                        if(window.confirm("Do you want to delete this live set?")) {
                                            var fd=new FormData();
                                            fd.append("livesetid",e.liveset_slno);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/livesetdelete",fd);
                                            var data=resp.data;
                                            getLivesetlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-times" style={icontimes}></i></button></td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            // pageCount={20}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            // disabledClassName={"pagination__link--disabled"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>

        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::LIVE EXAM MODIFICATION:::
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setLiveexamname(ev.target.value);
                }} value={liveexamname}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("livesetid",liveexamid);
                fd.append("livesetname",liveexamname);
                var resp=await axios.post("https://safalata.in/safalatalara/api/livesetnameupdate",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsOpen(false);
                    getLivesetlist();
                } else {
                    setIsmodalalert(true);
                    setModalmsg("Live Exam Set Already Exist!!")
                }
                                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default NewLiveExamContent;