import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function CronSettingsResetContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

   

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const selectright={
        paddingRight: '10px'
    };

    let [loader,setLoader]=useState(true);

    
    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [newsubjectname,setNewsubjectname]=useState("");

    // For Modal
    let [subjectname,setSubjectname]=useState("");
    //let [subjectid,setSubjectid]=useState("");
    let [subjectslno,setSubjectslno]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal

    let [fordays, setFordays]=useState("");
    let [startdate, setStartdate]=useState("");
    let [exam, setExam]=useState("");

    let [nextscheduledate,setNextscheduledate]=useState("");

    // let [isresetbtn,setIsresetbtn]=useState(false);

    let [password, setPassword]=useState("");

    let [resetsettingsarr,setResetsettingsarr]=useState([]);

    let [groupdiv,setGroupdiv]=useState("");
    let [groupid,setGroupid]=useState("");
    let [grouparr,setGrouparr]=useState([]);
    
    async function getGrouparr() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getgrouplist");
        var data=resp.data;
        setGrouparr(data.obj);
    }

    async function getresetsettings() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getresetsettings");
        var data=resp.data;
        setResetsettingsarr(data.obj);

        setLoader(false);
    }

    // async function resetallpracticeexam() {
    //     var fd=new FormData();
    //     fd.append("fordays",fordays);
    //     fd.append("startdate",startdate);
    //     var resp=await axios.post("https://safalata.net/safalatalara/api/resetsettings",fd);
    //     var data=resp.data;
    //     if (data.addalert==1) {
    //         getresetsettings();
    //         setIsnewbtn(true);
    //         setFordays("");
    //         setStartdate("");
    //         setIsalert(false);
    //     } else if (data.addalert==2) {
    //         setIsalert(true);
    //         setMsg(data.msg);
    //     }
    // }

    useEffect(()=>{
        getresetsettings();
        getGrouparr();
        setGroupdiv("d-none");
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">Cron Job Reset Settings</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className="mt-4 form-group">
                    <div style={selectright}>
                        <label>How Many Days for This Reset</label>
                        <input className="form-control" placeholder='Cron Job for how many days [e.g. 61]' onChange={(ev)=>{
                        setFordays(ev.target.value);
                            if (ev.target.value.length>1 && startdate.length>0 && exam.length>0) {
                                setIsnewbtn(false);
                                //addNewbatch();
                            } else {
                                setIsnewbtn(true);
                            }
                        }} value={fordays}/>
                        
                    </div>
                </div>
                <div className="mt-4 form-group">
                    <label>Select Start Exam Date</label>
                    <input type="date" className="form-control" placeholder='Start Exam Date' onChange={(ev)=>{
                        setStartdate(ev.target.value);
                        if (ev.target.value.length>0 && fordays.length>1 && exam.length>0) {
                            setIsnewbtn(false);
                            //addNewbatch();
                        } else {
                            setIsnewbtn(true);
                        }
                    }} value={startdate}/>
                </div>

                <div className="mt-4 form-group">
                    <label>Select Exam Type</label>
                    <select className="form-control form-select" onChange={(ev)=>{
                        setExam(ev.target.value);

                        //alert(groupdiv);

                        if (ev.target.value==1) {
                            setGroupdiv("d-block");
                            //alert(groupdiv);
                        } else {
                            setGroupid("");
                            setGroupdiv("d-none");
                        }
                        
                        if (ev.target.value.length>0 && fordays.length>1 && startdate.length>0) {
                            setIsnewbtn(false);
                        } else {
                            setIsnewbtn(true);
                        }                      
                    }} value={exam}>
                    <option value="">--Select Exam Type--</option>
                    <option value="2">Practice Exam</option>
                    <option value="1">Live Exam</option>
                    </select>
                </div>

                <div className={"mt-4 form-group "+groupdiv}>
                <label>Select Serial Number (Optional)</label>
                    <select className="form-control form-select" onChange={(ev)=>{
                        setGroupid(ev.target.value);  
                        if (ev.target.value.length>0 && fordays.length>1 && startdate.length>0) {
                            setIsnewbtn(false);
                            //addNewbatch();
                        } else {
                            setIsnewbtn(true);
                        }                      
                    }} value={groupid}>
                    <option value="">START EXAM FROM FIRST SERIAL NUMBER</option>
                    {grouparr.map((e)=>
                        <option value={e.liveexamset_serial_no} key={e.liveexamset_serial_no}>{e.liveexamset_serial_no}</option>
                    )}
                    

                    </select>
                </div>

                <div className="mt-4 form-group">
                    <span className="form-group">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={async ()=>{
                            setIsOpen(true);
                            var fd=new FormData();
                            fd.append("fordays",fordays);
                            fd.append("startdate",startdate);
                            var resp=await axios.post("https://safalata.in/safalatalara/api/nextscheduledate",fd);
                            var data=resp.data;
                            setNextscheduledate(data);
                        }}>RESET EXAM SETTINGS</button>
                    </span>
                </div>

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    RESET EXAM SETTINGS DETAILS
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    {/* <th className='text-center' style={txtblod}>#</th> */}
                                    <th className='text-center' style={txtblod}>SERIAL NO</th>
                                    <th className='text-center' style={txtblod}>FOR DAYS</th>
                                    <th className='text-center' style={txtblod}>RESET START DATE</th>
                                    {/* <th className='text-center' style={txtblod}>RE-SCHEDULE DATE</th> */}
                                    <th className='text-center' style={txtblod}>RESET EXAM TYPE</th>
                                    <th className='text-center' style={txtblod}>STATUS</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {resetsettingsarr.map((e)=>
                                <tr key={e.id}>
                                    <td className='text-center' style={txtblod}>{e.groupid>0?e.groupid:'FIRST SERIAL NUMBER'}</td>
                                    <td className='text-center' style={txtblod}>{e.fordays}</td>
                                    <td className='text-center' style={txtblod}>{dateFormat(e.startdate, "dd-mm-yyyy")}</td>
                                    {/* <td className='text-center' style={txtblod}>{dateFormat(e.startdate, "dd-mm-yyyy")}</td> */}
                                    <td className='text-center' style={txtblod}>{e.exam==1?"Live Exam":"Practice Exam"}</td>
                                    {e.status==1?
                                    <td className='text-center' style={txtgreen}>ACTIVE</td>
                                    :<td className='text-center' style={txtred}>CLOSED</td>}
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>
        </div>

        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::RESET EXAMINATION:::
            </div>

            <div className="form-group" style={formgroup}>
                <label>Start Date::</label>
                <input type="text" className="form-control" value={dateFormat(startdate, "dd-mm-yyyy")} disabled/>
            </div>

            <div className="form-group" style={formgroup}>
                <label>Next Schedule Date::</label>
                <input type="text" className="form-control" value={nextscheduledate} disabled/>
            </div>

            <div className="form-group" style={formgroup}>
                <label>Enter Right Password::</label>
                <input type="password" className="form-control" placeholder='Enter Password' onChange={(ev)=>{
                    setPassword(ev.target.value);
                }}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                // alert(password)
                // setIsresetbtn(true);
                setIsmodalalert(false);
                alert(groupid);
                var fd=new FormData();
                fd.append("fordays",fordays);
                fd.append("startdate",startdate);
                fd.append("exam",exam);
                fd.append("password",password);
                fd.append("groupid",groupid);
                var resp=await axios.post("https://safalata.in/safalatalara/api/checkpasswordforreset",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsnewbtn(true);
                    getresetsettings();
                    // setTimeout(()=>{
                        setIsOpen(false);                        
                        setFordays("");
                        setStartdate("");
                        setExam("");
                        setGroupid("");
                        setGroupdiv("d-none");
                    // },1000);                    
                } else {
                    setIsmodalalert(true);
                    setModalmsg("Wrong Password");
                }
                                
            }} className="btn btn-primary" >Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default CronSettingsResetContent;