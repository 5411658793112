import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

// For Modal
import Modal from 'react-modal';
// For Modal

// For Import QA Loader
import {data} from "../include/Loader";

import "../loader.css";
// For Import QA Loader

function AddPracticesetAsLiveContent() {

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // For Modal

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const allmargin={
        margin: '30px',
        fontSize: 'large',
        fontWeight: 700
    };

    const selectright={
        paddingRight: '10px'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'large'
    };

    let [liveexamlist, setLiveexamlist]=useState([]);
    let [practiceexamlist, setPracticeexamlist]=useState([]);
    let [liveexamid, setLiveexamid]=useState("");
    let [practiceexamid, setPracticeexamid]=useState("");


    let [livesetname,setLivesetname]=useState("");
    let [subjectname,setSubjectname]=useState("");
    let [subjectid,setSubjectid]=useState("");
    let [coursename,setCoursename]=useState("");
    let [courseid,setCourseid]=useState("");
    let [qstcount,setQstcount]=useState("");
    let [practicesetname,setPracticesetname]=useState("");

    // let [loader,setLoader]=useState(true);

    async function getCopylivepracticeexamlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/copylivepracticeexamlist");
        var data=resp.data;
        setLiveexamlist(data.copy);
        setPracticeexamlist(data.to);
    }

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [isalert,setIsalert]=useState(false);


    useEffect(()=>{
        getCopylivepracticeexamlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    COPY QUESTION ANSWER FROM LIVE EXAM TO PRACTICE EXAM 
                </div>
                <div className="card-body">
                    
                    <div className='container' style={batchbg}>

                        <div className='row'>
                            <div className="mt-4 col-6">
                                <div style={selectright}>
                                    <select className="form-control form-select" onChange={async(ev)=>{
                                        setLiveexamid(ev.target.value);
                                        if (ev.target.value.length>0) {
                                            var fd=new FormData();
                                            fd.append("liveexamid",ev.target.value);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamqstdetails",fd);
                                            var data=resp.data;
                                            setLivesetname(data.liveset_name)
                                            setSubjectname(data.subjectname);
                                            setCoursename(data.course_name);
                                            setQstcount(data.qstcount);
                                            setSubjectid(data.subject_slno);
                                            setCourseid(data.course_slno);
                                            setIsalert(false);
                                        } else {
                                            setIsnewbtn(true);
                                            setIsalert(false);
                                        }                                
                                    }}>
                                    <option value="">---------SELECT LIVE EXAM (COPY FROM)---------</option>

                                    {liveexamlist.map((c)=>
                                        <option key={c.liveset_slno} value={c.liveset_slno}>&#8594; {c.liveset_name}</option>                           
                                    )}

                                    </select>
                                </div>
                            </div>

                            <div className="mt-4 col-6">
                                <div style={selectright}>
                                    <select className="form-control form-select" onChange={async (ev)=>{
                                        setPracticeexamid(ev.target.value);
                                        if (ev.target.value.length>0) {
                                            var fd=new FormData();
                                            fd.append("practiceexamid",ev.target.value);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/getpracticeexamdetails",fd);
                                            var data=resp.data;
                                            setPracticesetname(data.practiceset_name);
                                            setIsnewbtn(false);
                                            setIsalert(false);
                                        } else {
                                            setIsnewbtn(true);
                                            setIsalert(false);
                                        }
                                    }}>
                                    <option value="">---------SELECT PRACTICE EXAM (COPY TO)---------</option>

                                    {practiceexamlist.map((d)=>
                                        <option key={d.practiceset_slno} value={d.practiceset_slno}>&#8594; {d.practiceset_name}</option>                           
                                    )}

                                    </select>
                                </div>
                            </div>
                        </div>    
                        
                    </div>

                </div>

                {isalert?
                <div className="mt-4 card-body">
                    
                    <div className='container'>
                        <div className='mt-4 row'>
                            <div className="col-12 text-center" style={{fontSize:'large', color:'#01691d', fontWeight: 700}}><i className='fas fa-check-double'></i> Data Copy Success!!</div>
                        </div>
                    </div>

                </div>
                :''}

                {liveexamid>0?
                <div className="mt-4 card-body">
                    
                    <div className='container' style={batchbg}>
                        <div className='row'>
                            <div className="mt-4 col-2"></div>
                            <div className="mt-4 col-8">
                                <div style={allmargin}>
                                    COURSE NAME : <span style={txtred}>{coursename}</span>
                                </div>
                                <div style={allmargin}>
                                    SUBJECT NAME : <span style={txtred}>{subjectname}</span>
                                </div>
                                <div style={allmargin}>
                                    COPY FROM LIVE EXAM : <span style={txtred}>{livesetname}</span>
                                </div>
                                {practiceexamid>0?
                                <div style={allmargin}>
                                    COPY TO PRACTICE EXAM : <span style={txtred}>{practicesetname}</span>
                                </div>
                                :''}
                                <div style={allmargin}>
                                    TOTAL QUESTIONS : <span style={txtred}>{qstcount}</span>
                                </div>
                                <div style={allmargin} className="text-center">
                                    <input type="button" className="btn btn-success" value="IMPORT TO PRACTICE EXAM" onClick={async()=>{
                                        
                                        if (window.confirm("Copy Question & Answer from Live Exam To Practice Exam?")) {
                                            setIsOpen(true);
                                            openModal();
                                            var fd=new FormData();
                                            fd.append("liveexamid",liveexamid);
                                            fd.append("practiceexamid",practiceexamid);
                                            fd.append("subjectid",subjectid);
                                            fd.append("courseid",courseid);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/copydatafromlivetopractice",fd);
                                            var data=resp.data;
                                            setIsalert(true);
                                            setIsnewbtn(true);
                                            getCopylivepracticeexamlist();
                                            setLiveexamlist([]);
                                            setPracticeexamlist([]);
                                            setLiveexamid("");
                                            setPracticeexamid("");
                                            setIsOpen(false);
                                        }                                      

                                    }} disabled={isnewbtn}/> 
                                </div>
                            </div>
                            <div className="mt-4 col-2"></div>

                        </div>    
                        
                    </div>

                </div>
                :''}




            </div>
        </div>
        

        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="main">
                <div className="loaderWrapper">
                    {data.map((loader, index) => (
                    <div key={loader.name + index} className="loaderBox">
                        <loader.Component {...loader.props} />
                    </div>
                    ))}
                </div>
            </div>                        
        </Modal>
        {/* FOr Modal */}

        
        </>
    );

}

export default AddPracticesetAsLiveContent;