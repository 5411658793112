//import {Link} from 'react-router-dom';

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//import DateCountDown from 'react-date-countdown-timer';

function ManageLiveExamSetContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconpending={
        color: '#900000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [livesetnewarr, setLivesetnewarr]=useState([]);
    let [livesetrunningarr, setLivesetrunningarr]=useState([]);
    let [livesetclosedarr, setLivesetclosedarr]=useState([]);


    let [loader,setLoader]=useState(true);
    let [loader2,setLoader2]=useState(true);
    let [loader3,setLoader3]=useState(true);

    let [countnew, setCountnew]=useState(0);
    let [countrunning, setCountrunning]=useState(0);
    let [countclosed, setCountclosed]=useState(0);
    
    // TIME INTERVAL COUNTDOWN
    // const [timerday,setTimerday]=useState("00");
    // const [timerhour,setTimerhour]=useState("00");
    // const [timermin,setTimermin]=useState("00");
    // const [timersec,setTimersec]=useState("00");

    const [datetimeinterval,setDateTimeInterval]=useState("");

    const [enddate,setEnddate]=useState("")

    let interval = useRef();

    const startTimer = (enddt,slno) => {
    //function startTimer(enddt) {
        //const coundownDate = new Date('March 30, 2022 00:00:00').getTime();
        const coundownDate = new Date(enddt).getTime();

        interval=setInterval(()=>{
            const now = new Date().getTime();
            const distance = coundownDate-now;

            const days=Math.floor(distance / (1000*60*60*24));
            const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
            const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
            const secs=Math.floor((distance % (1000*60)) / 1000);

            if (distance < 0) {
                //stop timer code
                clearInterval(interval.current);
                //clearInterval(interval);
                setDateTimeInterval("Schedule End");
            } else {
                // setTimerday(days);
                // setTimerhour(hours);
                // setTimermin(mins);
                // setTimersec(secs);
                setDateTimeInterval(days+"d "+hours+"h "+mins+"m "+secs+"s")
                //const slno=days+"d "+hours+"h "+mins+"m "+secs+"s";
            }
        
        //}, 1000)
        }, 1)
    };
    // TIME INTERVAL COUNTDOWN


    async function getLivesetcount() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getlivesetcount");
        var data=resp.data;
        setCountnew(data.datanewcount);
        setCountrunning(data.datarunningcount);
        setCountclosed(data.dataclosedcount);
        //setCountnew(data.datanewcount);
    }

    let [livesettodayarr, setLivesettodayarr]=useState([]);
    let [counttoday, setCounttoday]=useState("");

    async function getLivesettodaylist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getlivesettodaylist");
        var data=resp.data;
        setLivesettodayarr(data.arr);
        setCounttoday(data.counttoday);
        setLoader(false);
    }

    async function getLivesetnewlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getlivesetnewlist");
        var data=resp.data;
        setLivesetnewarr(data);
        setLoader(false);
    }

    async function getLivesetrunninglist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getlivesetrunninglist");
        var data=resp.data;
        setLivesetrunningarr(data);
        setLoader2(false);
    }

    async function getLivesetclosedlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getlivesetclosedlist");
        var data=resp.data;
        setLivesetclosedarr(data);
        setLoader3(false);
    }


    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [isnewbtnmodal,setIsnewbtnmodal]=useState(true);

    let [newliveexamname,setNewliveexamname]=useState("");

    let [livesetserialid,setLivesetserialid]=useState("");
    let [isserialid,setIsserialid]=useState(false);
    let [livesetmode,setLivesetmode]=useState("");

    // For Modal
    let [liveexamname,setLiveexamname]=useState("");
    let [liveexamid,setLiveexamid]=useState("");

    // let [liveexamname2,setLiveexamname2]=useState("");
    // let [liveexamid2,setLiveexamid2]=useState("");

    let [modelid,setModelid]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal

    useEffect(()=>{
        getLivesetcount();
        getLivesetnewlist();
        getLivesetrunninglist();
        getLivesetclosedlist();
        getLivesettodaylist();

        // TIMER INTERVAL
        startTimer();
        return () => {
        //clearInterval(interval.current);
             clearInterval(interval);
        };
        // TIMER INTERVAL

    },[])



    return(
        <>
        <div className="container-fluid px-4">
            


        <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    TODAY EXAM SELECTION LIST - TOTAL TODAY EXAM ( {counttoday} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>Live Set ID</th>
                                    <th style={txtblod}>Live Set Name</th>
                                    <th className='text-center' style={txtblod}>Total Question</th>
                                    <th className='text-center' style={txtblod}>Total Answer</th>
                                    <th className='text-center' style={txtblod}>Exam Status</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {livesettodayarr.map((e)=>
                            
                            <tr key={e.liveset_slno}>
                                
                                <td className='text-center' style={txtblod}>{e.liveset_slno}</td>
                                <td style={txtblod}>{e.liveset_name}</td>
                                <td className='text-center' style={txtblod}>{e.countqid}</td>
                                <td className='text-center' style={txtblod}>{e.countanswer}</td>
                                {e.liveset_status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}
                                
                            </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>


            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    NEW EXAM SELECTION LIST - TOTAL NEW EXAM ( {countnew} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>Live Set ID</th>
                                    <th style={txtblod}>Live Set Name</th>
                                    <th className='text-center' style={txtblod}>Total Question</th>
                                    <th className='text-center' style={txtblod}>Exam Status</th>
                                    <th className='text-center' style={txtblod}>End Duration</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {livesetnewarr.map((e)=>
                            
                            <tr key={e.liveset_slno}>
                                
                                <td className='text-center' style={txtblod}>{e.liveset_slno}</td>
                                <td style={txtblod}>{e.liveset_name}</td>
                                <td className='text-center' style={txtblod}>{e.countqid}</td>

                                {e.liveset_status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}
                                <td className='text-center' style={txtblod}>NEW EXAM SELECTION</td>
                                <td className='text-center'>
                                    {e.countqid>0?
                                    // <button onClick={async ()=>{
                                    //     if(window.confirm("Sure, Is This Exam Selected?")) {
                                    //         var fd=new FormData();
                                    //         fd.append("livesetid",e.liveset_slno);
                                    //         var resp=await axios.post("https://safalata.in/safalatalara/api/addliveexamsetselection",fd);
                                    //         var data=resp.data;
                                    //         getLivesetnewlist();
                                    //         getLivesetcount();
                                    //     }
                                    // }} className="btn btn-primary"><i className="fas fa-check-double" style={icontimes}></i> Selection</button>
                                    <button onClick={async ()=>{
                                        // setModelid(2);
                                        openModal();
                                        setLiveexamid(e.liveset_slno);
                                        setLiveexamname(e.liveset_name);
                                    }} className="btn btn-primary"><i className="fas fa-check-double" style={icontimes}></i> Selection</button>
                                    
                                    :<button className="btn btn-primary" disabled={isnewbtn}><i className="fas fa-check-double" style={iconpending}></i> Selection</button>}
                                </td>
                            </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    RUNNING EXAM SELECTED LIST - TOTAL RUNNING EXAM ( {countrunning} )
                </div>
                <div className="card-body">
                    
                {!loader2?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Live Set ID</th>
                                    <th style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>Live Set Name</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Total Question</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Exam Status</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>End Duration</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {livesetrunningarr.map((e)=>
                                
                                
                            <tr key={e.liveset_slno}>
                                
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>{e.liveset_slno}</td>
                                <td style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>{e.liveset_name}</td>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.countqid}</td>

                                {e.liveset_status==1?
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%', color: '#01691d'}}>ACTIVE</td>
                                :<td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%', color: '#c70000'}}>INACTIVE</td>}
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '20%', color: '#01691d'}}>
                                    {/* return <DateCountDown dateTo='February 20, 2022 09:19:00' callback={()=>
                                    setIsalert(false)
                                    }/> */}
                                    
                                    {/* {startTimer(dateFormat(e.liveexamsetselection_end_schedule, "mmmm dd, yyyy HH:MM:00"),e.liveset_slno)}
                                    {datetimeinterval} */}

                                    {dateFormat(e.liveexamsetselection_end_schedule, "dd-mm-yyyy HH:MM TT")}
                                    

                                    {/* {
                                        setInterval(()=>{
                                            const now = new Date().getTime();
                                            const distance = Date(dateFormat(e.liveexamsetselection_end_schedule, "mmmm dd, yyyy HH:MM:00")).getTime()-now;//coundownDate-now;
                                
                                            const days=Math.floor(distance / (1000*60*60*24));
                                            const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
                                            const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
                                            const secs=Math.floor((distance % (1000*60)) / 1000);
                                
                                            if (distance < 0) {
                                                const dtinterval = "Schedule End";
                                            } else {
                                                const dtinterval = days+"d "+hours+"h "+mins+"m "+secs+"s";
                                            }
                                        
                                        }, 1)
                                    } */}
                                    

                                </td>
                                <td className='text-center' style={{width: '15%'}}>
                                <button className="btn btn-primary" disabled={isnewbtn}><i className="fas fa-check-double" style={iconpending}></i> Selected</button>
                                </td>
                            </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>



            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    CLOSED EXAM SELECTED LIST - TOTAL CLOSED EXAM ( {countclosed} )
                </div>
                <div className="card-body">
                    
                {!loader3?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Group ID</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Live ID</th>
                                    <th style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>Live Set Name</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Total Question</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%'}}>Next Schedule Date</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>End Duration</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {livesetclosedarr.map((e)=>
                                
                            <tr key={e.liveset_slno}>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveexamset_serial_no}</td>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveset_slno}</td>
                                <td style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>{e.liveset_name}</td>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.countqid}</td>

                                {/* {e.liveset_status==1?
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%', color: '#01691d'}}>ACTIVE</td>
                                :<td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%', color: '#c70000'}}>INACTIVE</td>
                                } */}
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%', color: '#01691d'}}>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy HH:MMTT")+'-'+dateFormat(e.liveexamsetselection_end_schedule, "HH:MMTT")}</td>

                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%', color: '#c70000'}}>
                                    EXAM CLOSED
                                </td>
                                <td className='text-center' style={{width: '15%'}}>
                                {/* <button className="btn btn-primary" disabled={isnewbtn}><i className="fas fa-check-double" style={iconpending}></i> Selected</button> */}
                                <button className="btn btn-primary" onClick={async ()=>{
                                    // setModelid(2);
                                    openModal();
                                    setLiveexamid(e.liveset_slno);
                                    setLiveexamname(e.liveset_name);
                                }}><i className="fas fa-check-double" style={iconpending}></i> Selection</button>
                                </td>
                            </tr>

                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>




        </div>


        {/* For Modal */}
        {/* <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::LIVE EXAM MODIFICATION:::
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setLiveexamname(ev.target.value);
                }} value={liveexamname}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("livesetid",liveexamid);
                fd.append("livesetname",liveexamname);
                var resp=await axios.post("https://safalata.in/safalatalara/api/livesetnameupdate",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsOpen(false);
                    //getLivesetlist();
                } else {
                    setIsmodalalert(true);
                    setModalmsg("Live Exam Set Already Exist!!")
                }
                                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal> */}

        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::LIVE EXAM SELECTION:::
            </div>
            <div className="form-group" style={formgroup}>
                LIVE SET ID : {liveexamid}
            </div>
            <div className="form-group" style={formgroup}>
                LIVE SET NAME : {liveexamname}
            </div>
            <div className="form-group" style={formgroup}>
                <select className="form-control form-select" onChange={(ev)=>{
                    setLivesetmode(ev.target.value);
                    if (ev.target.value==1) {
                        setLivesetserialid("");
                        setIsserialid(true);
                        setIsnewbtnmodal(false);
                    } else if (ev.target.value==7 && livesetserialid.length>0) {
                        setIsserialid(false);
                        setIsnewbtnmodal(false);
                    } else {
                        setIsserialid(false);
                        setIsnewbtnmodal(true);
                    }
                }} >
                    <option value="">----select live exam mode----</option>
                    <option value="7">Daily Exam</option>
                    <option value="1">Weekly Exam</option>
                </select>
                {/* {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''} */}
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setLivesetserialid(ev.target.value);
                    if (livesetmode==7 && ev.target.value.length>0) {
                        setIsserialid(false);
                        setIsnewbtnmodal(false);
                    } else {
                        setIsnewbtnmodal(true);
                    }
                }} placeholder="SERIAL NUMBER FOR THAT DAY [e.g. 9]" value={livesetserialid} disabled={isserialid}/>
                {/* {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''} */}
            </div>
            
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                // alert(liveexamid+', '+livesetserialid+', '+livesetmode);
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("livesetid",liveexamid);
                fd.append("livesetserialid",livesetserialid);
                fd.append("livesetmode",livesetmode);
                // alert(liveexamid+', '+livesetserialid+', '+livesetmode);
                var resp=await axios.post("https://safalata.in/safalatalara/api/addliveexamsetselectionnew",fd);
                var data=resp.data;
                
                setIsnewbtnmodal(true);
                setTimeout(()=>{
                    setIsOpen(false);
                    getLivesetcount()
                    getLivesetclosedlist();
                    setLivesetserialid("");
                    setLivesetmode("");
                }, 100)
                       
            }} className="btn btn-primary" disabled={isnewbtnmodal}>Save Changes</button>
            </div>
        </Modal>

        {/* FOr Modal */}
        </>
    );

}

export default ManageLiveExamSetContent;