//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function NewPracticeExamContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [practiceexamlistarr, setPracticeexamlistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    let [count, setCount]=useState("");

    async function getPracticesetlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/newpracticesetlist");
        var data=resp.data;
        setPracticeexamlistarr(data.obj);
        setCount(data.datacount);
        setLoader(false);
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [newpracticeexamname,setNewpracticeexamname]=useState("");

    // For Modal
    let [practiceexamname,setPracticeexamname]=useState("");
    let [practiceexamid,setPracticeexamid]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal
       
    async function addNewpracticeexam() {
        var fd=new FormData();
        fd.append("newpracticeexamname",newpracticeexamname);
        var resp=await axios.post("https://safalata.in/safalatalara/api/addnewpracticeset",fd);
        var data=resp.data;
        if (data.addalert==1) {
            getPracticesetlist();
            setIsnewbtn(true);
            setNewpracticeexamname("");
            setIsalert(false);
        } else if (data.addalert==2) {
            setIsalert(true);
            setMsg(data.msg);
        }
        
    }

    useEffect(()=>{
        getPracticesetlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">NEW PRACTICE EXAM SET</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className="mt-4 input-group">
                    <input type="text" className="form-control" placeholder='New Practice Exam Set Name' onChange={(ev)=>{
                        setNewpracticeexamname(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                            //addNewbatch();
                        } else {
                            setIsnewbtn(true);
                        }
                    }} value={newpracticeexamname}/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={addNewpracticeexam}>ADD NEW PRACTICE EXAM</button>
                    </span>
                </div>

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    PRACTICE EXAM SET LIST - TOTAL COUNT ( {count} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>Practice Set ID</th>
                                    <th style={txtblod}>Practice Set Name</th>
                                    {/* <th className='text-center' style={txtblod}>Created Date</th> */}
                                    <th className='text-center' style={txtblod}>Practice Set Status</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {practiceexamlistarr.map((e)=>
                                <tr key={e.course_slno}>
                                <td className='text-center' style={txtblod}>{e.practiceset_slno}</td>
                                <td style={txtblod}>{e.practiceset_name}</td>
                                {/* <td className='text-center' style={txtblod}>{dateFormat(e.created_at, "dd-mm-yyyy")}</td> */}

                                {e.practiceset_status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}

                                <td className='text-center'><button onClick={async ()=>{
                                        openModal();
                                        setPracticeexamid(e.practiceset_slno);
                                        setPracticeexamname(e.practiceset_name);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button>

                                    {e.practiceset_status==1?<button onClick={async ()=>{
                                        if (window.confirm("Do you want to inactive this practice set?")) {
                                            var fd=new FormData();
                                            fd.append("practicesetid",e.practiceset_slno);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/practicesetactiveinactive",fd);
                                            var data=resp.data;
                                            getPracticesetlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    :
                                    <button onClick={async ()=>{
                                        if (window.confirm("Do you want to active this practice set?")) {
                                            var fd=new FormData();
                                            fd.append("practicesetid",e.practiceset_slno);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/practicesetactiveinactive",fd);
                                            var data=resp.data;
                                            getPracticesetlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    }

                                    <button onClick={async ()=>{
                                        if(window.confirm("Do you want to delete this practice set?")) {
                                            var fd=new FormData();
                                            fd.append("practicesetid",e.practiceset_slno);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/practicesetdelete",fd);
                                            var data=resp.data;
                                            getPracticesetlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-times" style={icontimes}></i></button></td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>
        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::PRACTICE EXAM MODIFICATION:::
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setPracticeexamname(ev.target.value);
                }} value={practiceexamname}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("practicesetid",practiceexamid);
                fd.append("practicesetname",practiceexamname);
                var resp=await axios.post("https://safalata.in/safalatalara/api/practicesetnameupdate",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsOpen(false);
                    getPracticesetlist();
                } else {
                    setIsmodalalert(true);
                    setModalmsg("Practice Exam Set Already Exist!!")
                }
                                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default NewPracticeExamContent;