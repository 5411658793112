import Nav from "../include/Nav";
import Sidenav from "../include/Sidenav";
import Footer from "../include/Footer";

import ActiveStudentContent from "../content/ActiveStudentContent";

function ActiveStudent() {

    return(
    <>
    <body className="sb-nav-fixed">
        <Nav/>
        <div id="layoutSidenav">
            <Sidenav/>
            <div id="layoutSidenav_content">
                <main>
                <ActiveStudentContent/>
                </main>
                <Footer/>
            </div>
        </div>
    </body>
    </>
    );
}

export default ActiveStudent;