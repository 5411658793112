
import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function ClosedLiveExamListContent() {

    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconpending={
        color: '#900000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    let [livesetclosedweeklyarr, setLivesetclosedweeklyarr]=useState([]);
    let [livesetcloseddailyarr, setLivesetcloseddailyarr]=useState([]);

    let [loader,setLoader]=useState(true);
    let [loader2,setLoader2]=useState(true);

    let [countclosedexamdaily, setCountclosedexamdaily]=useState(0);
    let [countclosedexamweekly, setCountclosedexamweekly]=useState(0);

    async function getLivesetcount() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getlivesetcount");
        var data=resp.data;
        setCountclosedexamdaily(data.dataclosedexamdailycount);
        setCountclosedexamweekly(data.dataclosedexamweeklycount);
    }

    async function getClosedweeklyexamlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getclosedweeklyexamlist");
        var data=resp.data;
        setLivesetclosedweeklyarr(data);
        setLoader(false);
    }
    async function getCloseddailyexamlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getcloseddailyexamlist");
        var data=resp.data;
        setLivesetcloseddailyarr(data);
        setLoader2(false);
    }      
    

    useEffect(()=>{
        getLivesetcount();
        getClosedweeklyexamlist();
        getCloseddailyexamlist();
    },[])



    return(
        <>
        <div className="container-fluid px-4">
            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    CLOSED EXAM LIST (WEEKLY - SATURDAY AND SUNDAY) - TOTAL CLOSED EXAM ( {countclosedexamweekly} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    {/* <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Group ID</th> */}
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Live ID</th>
                                    <th style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>Live Set Name</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Total Question</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%'}}>Next Schedule Date</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>End Duration</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {livesetclosedweeklyarr.map((e)=>
                                
                            <tr key={e.liveset_slno}>
                                {/* <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveexamset_serial_no}</td> */}
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveset_slno}</td>
                                <td style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>{e.liveset_name}</td>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.countqid}</td>

                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%', color: '#01691d'}}>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy HH:MMTT")+'-'+dateFormat(e.liveexamsetselection_end_schedule, "HH:MMTT")}</td>

                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%', color: '#c70000'}}>
                                    EXAM CLOSED
                                </td>
                                <td className='text-center' style={{width: '15%'}}>
                                <button className="btn btn-primary" disabled><i className="fas fa-check-double" style={iconpending}></i> Selection</button>
                                </td>
                            </tr>

                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    CLOSED EXAM LIST (DAILY) - TOTAL CLOSED EXAM ( {countclosedexamdaily} )
                </div>
                <div className="card-body">
                    
                {!loader2?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Group ID</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Live ID</th>
                                    <th style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>Live Set Name</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Total Question</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%'}}>Next Schedule Date</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>End Duration</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {livesetcloseddailyarr.map((e)=>
                                
                            <tr key={e.liveset_slno}>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveexamset_serial_no}</td>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveset_slno}</td>
                                <td style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>{e.liveset_name}</td>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.countqid}</td>

                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%', color: '#01691d'}}>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy HH:MMTT")+'-'+dateFormat(e.liveexamsetselection_end_schedule, "HH:MMTT")}</td>

                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%', color: '#c70000'}}>
                                    EXAM CLOSED
                                </td>
                                <td className='text-center' style={{width: '15%'}}>
                                <button className="btn btn-primary" disabled><i className="fas fa-check-double" style={iconpending}></i> Selection</button>
                                </td>
                            </tr>

                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>


        </div>


        </>
    );

}

export default ClosedLiveExamListContent;