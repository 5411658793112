import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

function ManageQAContent() {

    

    // For Modal
    const customStyles = {
        content: {
          width: '40%',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txttdblod={
        fontWeight: '500',
        fontSize: '1.1em'
    };

    const txtcorrect={
        fontWeight: '700',
        fontSize: '1.3em',
        color: '#009127'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconplus={
        color: '#001be3',
        cursor: 'pointer',
        fontSize: '1em'
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: '1em'
    };

    const iconnonedit={
        color: '#909090',
        fontSize: '1em'
    };

    const icontrash={
        color: '#e3003b',
        fontSize: '1em'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const selectright={
        paddingRight: '10px'
    };

    const div70={
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };


    const txtfont12rem={
        fontWeight: 700,
        fontSize: '1.2rem'
    }
    const txtfont1rem={
        fontWeight: 700,
        fontSize: '1rem'
    }

    let [searchqid,setSearchQID]=useState("");

    // Pagination
    let [totaldata,setTotaldata]=useState("");
    
    let [pageCount,setPageCount]=useState("");
    
    //let [limit,setLimit]=useState(6);
    // Pagination

    let [qalistallarr, setQalistallarr]=useState([]);

    let [qalistarr, setQalistarr]=useState([]);
    let [loader,setLoader]=useState(false);

    

    

    // let [isalert,setIsalert]=useState(false);
    // let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);

    // For Modal
    let [question, setQuestion]=useState("");
    let [qid, setQid]=useState("");
    let [answer, setAnswer]=useState("");
    let [noted, setNoted]=useState("");

    let [updatequestion, setUpdatequestion]=useState("");
    //let [updatenoted, setUpdatenoted]=useState("");
    let [qslno, setQslno]=useState("");
    // For Modal

    // For Modal Check
    let [modalid,setModalid]=useState(0);
    // For Modal Check
    
    let [isradio, setIsradio]=useState(true);
    let [updateanswer, setUpdateanswer]=useState("");
    let [anslistarr, setAnslistarr]=useState([]);


    async function getAnswerlist() {
        var fd=new FormData();
        fd.append('ansqid',qid);
        var resp=await axios.post("https://safalata.in/safalatalara/api/getanslist",fd);
        var data=resp.data;
        setAnslistarr(data);
    }

    let limit = 50;
    let [pageno,setPageno]=useState("1");
    
    const handlePageClick = async (event) => {
        let page=event.selected+1;
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",page);
        fd.append("limit",limit);
        fd.append("searchqid",searchqid);
        var resp=await axios.post("https://safalata.in/safalatalara/api/getqalist",fd);
        var data=resp.data;
        setQalistarr(data);
        //setCount(data.datacount);
        setLoader(false);
    };


    async function getQacount() {
        // var fd=new FormData();
        // fd.append("searchqid",searchqid); //added
        var resp=await axios.get("https://safalata.in/safalatalara/api/getqacount");
        //var resp=await axios.post("http://localhost/ADMINSAFALATA/safalata/api/getqacount",fd);
        var data=resp.data;
        setTotaldata(data);
        const total=data;
        setPageCount(Math.ceil(total/limit));
    }

    async function getQalist() {
        setLoader(true);

        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("limit",limit);
        fd.append("searchqid",searchqid);
        var resp2=await axios.post("https://safalata.in/safalatalara/api/getqalist", fd);
        var data2=resp2.data;
        setQalistarr(data2);

        setLoader(false);
    }



    async function getQalistall() {
        setLoader(true);
        var resp2=await axios.get("https://safalata.in/safalatalara/api/getqalistall");
        var data2=resp2.data;
        setQalistallarr(data2);
        
        setLoader(false);
    }



    useEffect(()=>{
        
        // getCourselist()
        getQacount();
        getQalist();
        //setPageno(1);

        getQalistall();

    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">MANAGE QUESTION ANSWER</h2>

            <div className='container' style={batchbg}>
                <div className="mt-4 input-group" style={div70}>
                    <input type="text" className='form-control' onChange={ async (ev)=>{
                        // setSearch(ev.target.value);
                        // getStudlist();

                        if (ev.target.value.length>0) {
                            setSearchQID(ev.target.value);
                            setIsnewbtn(false);
                            //getQacount();
                        } else if (ev.target.value=="") {
                            setIsnewbtn(true);
                            setLoader(true);

                            
                            var fd=new FormData();
                            fd.append("pageno",pageno);
                            fd.append("limit",limit);
                            fd.append("searchqid","");
                            var resp=await axios.post("https://safalata.in/safalatalara/api/getqalist",fd);
                            var data=resp.data;
                            setQalistarr(data);
                            setLoader(false);
                            setSearchQID("");
                            //getQacount();
                        }
                    }} placeholder='Search QID'/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getQalist}>SEARCH</button>
                    </span>
                </div>

            </div>

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    QUESTION ANSWER LIST (TOTAL - {totaldata})
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>QID</th>
                                    <th style={txtblod}>QUESTION</th>
                                    <th className='text-center' style={txtblod}>TOTAL OPTIONS</th>
                                    <th style={txtblod}>ANSWERS</th>
                                    <th className='text-center' style={txtblod}>ACTION</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {qalistarr.map((e)=>
                                <tr key={e.q_slno}>
                                <td className='text-center' style={txtfont12rem}>{e.qid}</td>
                                <td style={txtfont1rem}>{e.question}</td>
                                <td className='text-center' style={txtfont12rem}>{e.count}</td>
                                <td style={txttdblod}>
                                {e.ans.map((s)=>
                                    
                                    <div key={s.answer_slno}>&#9679; 
                                        {s.options==0?
                                        <span style={{fontWeight: 500, fontSize: '1rem'}}> {s.answer}</span>
                                        :<span style={txtcorrect}> {s.answer}</span>}<br/>
                                    </div>

                                )}
                                </td>
                                <td className='text-center'>
                                {e.count <= 4?
                                    <Link to="" className='btn-default' onClick={()=>{
                                    setModalid(1);
                                    openModal();
                                    setQuestion(e.question);
                                    setQid(e.qid);
                                }} style={{textDecoration: 'none', fontWeight: 900, fontSize: '1.3rem', marginTop: '15px'}}><span>&#43;</span></Link>
                                : <span style={{fontWeight: 700, fontSize: '1.4rem', textDecoration: 'none'}}>&#43;</span>
                                } &nbsp;&nbsp;
                                    <Link to="" className='btn-default' onClick={async ()=>{
                                    setModalid(2);
                                    openModal();
                                    setQuestion(e.question);
                                    setQid(e.qid);
                                    setQslno(e.q_slno);
                                    setNoted(e.noted);
                                    var fd=new FormData();
                                    fd.append('ansqid',e.qid);
                                    var resp=await axios.post("https://safalata.in/safalatalara/api/getanslist",fd);
                                    var data=resp.data;
                                    setAnslistarr(data);
                                    
                                }}><i className='fas fa-edit' style={iconedit}></i></Link>&nbsp;&nbsp;
                                    <Link to="" className='btn-default' onClick={async ()=>{
                                    if (window.confirm("Sure To Confirm Delete?")) {
                                        var fd=new FormData();
                                        fd.append('qid',e.qid);
                                        var resp=await axios.post("https://safalata.in/safalatalara/api/deleteqa",fd);
                                        var data=resp.data;

                                        var fd2=new FormData();
                                        fd2.append('qid',e.qid);
                                        var resp2=await axios.post("https://safalata.net/safalatalara/api/deleteqa",fd2);
                                        var data2=resp2.data;

                                        getQalist();
                                    }
                                }}><i className='fas fa-times' style={icontrash}></i></Link>
                                </td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>

        </div>

        {/* For Modal */}
        {modalid==1?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="ADD NEW ANSWER"
        >
            <h1 style={{ fontSize: '1.3em', textAlign: 'center'}}>:::ADD NEW ANSWER:::</h1> 
            <hr/>     
            <div className="form-group" style={formgroup}>
                QUESTION : {question}
            </div>
            <div className="form-group" style={formgroup}>
                QID : {qid}
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setAnswer(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }} placeholder='Add New Answer' />
            </div>
            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("qid",qid);
                fd.append("answer",answer);
                var resp=await axios.post("https://safalata.in/safalatalara/api/addnewanswer",fd);
                var data=resp.data;

                var resp2=await axios.post("https://safalata.net/safalatalara/api/addnewanswer",fd);
                var data2=resp2.data;
                
                setIsOpen(false);
                getQalist();
                                
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
        </Modal>
        :
        modalid==2?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Edit Question Answer"
        >
                                    
            <h1 style={{ fontSize: '1.3em', textAlign: 'center'}}>:::Edit Question Answer:::</h1> 
            <hr/>     

            <div className="form-group" style={formgroup}>
                QUESTION (QID-{qid}) :
            </div>
            
            <div className="mt-2 input-group" style={formgroup}>
                <input type="text" className="form-control" defaultValue={question} onChange={(ev)=>{
                    setUpdatequestion(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}/>
                <span className="input-group-btn">
                    <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={async()=>{
                        // alert(qid+' - '+updatequestion);
                        var fd=new FormData();
                        // fd.append("qslno",qslno);
                        fd.append("qid",qid);
                        fd.append("updatequestion",updatequestion);
                        var resp=await axios.post("https://safalata.in/safalatalara/api/updatequestion",fd);
                        var data=resp.data;

                        var resp2=await axios.post("https://safalata.net/safalatalara/api/updatequestion",fd);
                        var data2=resp2.data;

                        getQalist();
                    }}>UPDATE</button>
                </span>
            </div>

            <div className="form-group" style={{margin: '20px 10px', fontWeight: 700}}>
                ANSWER LIST :
            </div>

            <div className="form-row">
                <div className="col-md-12 mb-1">
                    {anslistarr.map((a)=>
                    <div className="input-group input-group-sm" key={a.answer_slno}>
                        <div className="input-group-text">

                        {a.options==0?
                        <input className="form-check-input" type="radio" name="radiobtn" onChange={async (ev)=>{
                            //var answer = window.confirm("Sure To Correct Answer?");
                            // if (window.confirm("Sure To Correct Answer?")) {
                            //if (answer) {
                                var fd=new FormData();
                                fd.append('q_id',a.qid);
                                fd.append('a_slno',a.answer_slno);
                                var resp=await axios.post("https://safalata.in/safalatalara/api/updateanswer",fd);
                                var data=resp.data;

                                var fd2=new FormData();
                                fd2.append('q_id',a.qid);
                                fd2.append('a_slno',a.answer_slno);
                                var resp2=await axios.post("https://safalata.net/safalatalara/api/updateanswer",fd2);
                                var data2=resp2.data;

                                getAnswerlist();
                                getQalist();
                            //}
                        }} style={{fontSize: 'large'}} value={a.answer_slno}/>
                        :
                        <input className="form-check-input" type="radio" name="radiobtn" style={{fontSize: 'large'}} checked={isradio} value={a.answer_slno}/>
                        }
                        </div>
                        <input type="text" className="form-control" defaultValue={a.answer} onChange={async (ev)=>{
                                var fd=new FormData();
                                fd.append('a_slno',a.answer_slno);
                                fd.append('a_answer',ev.target.value);
                                var resp=await axios.post("https://safalata.in/safalatalara/api/updateanswer",fd);
                                var data=resp.data;

                                var fd2=new FormData();
                                // fd2.append('q_id',a.qid);
                                // fd2.append('a_slno',a.answer_slno);
                                fd2.append('a_slno',a.answer_slno);
                                fd2.append('a_answer',ev.target.value);
                                var resp2=await axios.post("https://safalata.net/safalatalara/api/updateanswer",fd2);
                                var data2=resp2.data;

                                getAnswerlist();
                                getQalist();
                        }}/>
                        <div className="input-group-text">
                            <Link className="btn btn-default" to="" onClick={async()=>{
                            if (window.confirm("Sure To Confirm Delete Answer?")) {
                                var fd=new FormData();
                                fd.append('a_slno',a.answer_slno);
                                var resp=await axios.post("https://safalata.in/safalatalara/api/deleteanswer",fd);
                                var data=resp.data;

                                var resp2=await axios.post("https://safalata.net/safalatalara/api/deleteanswer",fd);
                                var data2=resp2.data;

                                getQalist();
                                getAnswerlist();
                                
                            }
                            }}><i className="fa fa-times" style={{color: 'red'}}></i></Link>
                        </div>
                    </div>
                    )}

                </div>
            </div>

            <div className="form-group" style={{margin: '20px 10px', fontWeight: 700}}>
                NOTES :
            </div>

            <div className="mt-2 input-group" style={formgroup}>
                <textarea className="form-control" defaultValue={noted} onChange={async (ev)=>{
                    var fd=new FormData();
                    // fd.append("qslno",qslno);
                    fd.append("qid",qid);
                    fd.append("updatenoted",ev.target.value);
                    var resp=await axios.post("https://safalata.in/safalatalara/api/updatequestion",fd);
                    var data=resp.data;

                    var resp2=await axios.post("https://safalata.net/safalatalara/api/updatequestion",fd);
                    var data2=resp2.data;

                    getQalist();
                }}/>
            </div>
            
            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
               setIsOpen(false);                                
            }} className="btn btn-primary">Close</button>
            </div>

        </Modal>
        : ''}
        {/* FOr Modal */}
        </>
    );

}

export default ManageQAContent;