import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

// import $ from "jquery";


// CONVERT JS TO PDF
import { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// CONVERT JS TO PDF


function MonthWiseLiveScoreContent() {

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblue={
        color: '#0e007a',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const selectright={
        paddingRight: '10px'
    };

    let [livescorelistarr, setLivescorelistarr]=useState([]);
    let [batchlistarr,setBatchlistarr]=useState([]);
    let [studlistarr, setStudlistarr]=useState([]);
    let [studbatchid,setStudbatchid]=useState("");
    let [studscorelistarr,setStudscorelistarr]=useState([]);
    let [scoremonthyear,setScoremonthyear]=useState("");
    
    let [studname,setStudname]=useState("");
    let [studenrollid,setStudenrollid]=useState("");
    let [studbatchname,setStudbatchname]=useState("");
    let [studmonthyear,setStudmonthyear]=useState("");

    let [loader,setLoader]=useState(false);

    //let [slno,setSlno]=useState(0);
    let slno=0;

    // CONVERT JS TO PDF
    let [ispdfbtn,setIspdfbtn]=useState(true);
    // CONVERT JS TO PDF
    

    async function getScoremonthyearlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getscoremonthyearlist");
        var data=resp.data;
        setLivescorelistarr(data);
    }

    async function getBatchlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/newbatchlist");
        var data=resp.data;
        setBatchlistarr(data);
    }

    // async function getLiveexambatchlist() {
    //     var fd=new FormData();
    //     fd.append("livesetslno",liveexamsetid);
    //     var resp=await axios.post("https://safalata.in/safalatalara/api/liveexambatchlist",fd);
    //     var data=resp.data;
    //     setExambatchlistarr(data);
    // }

    useEffect(()=>{
        getScoremonthyearlist();
        getBatchlist();
    },[])



    // CONVERT JS TO PDF
    const inputRef = useRef(null);
        const printDocument = () => {
            html2canvas(inputRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            pdf.addImage(imgData, "JPEG", -15, -10, 0, 0);
            pdf.save("download.pdf");
        });
    };
    // CONVERT JS TO PDF


    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">MONTH WISE SCORE SEARCH</h2>

            <div className='container' style={batchbg}>
                <div className='row'>
                    <div className="mt-4 col-4">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setScoremonthyear(ev.target.value);
                                if (ev.target.value.length>0) {
                                    setStudlistarr([]);
                                    setStudscorelistarr([]);
                                    setBatchlistarr([]);
                                    getBatchlist();

                                    setStudname("");
                                    setStudenrollid("");
                                    setStudbatchname("");
                                    setStudmonthyear("");
                                    setIspdfbtn(true);
                                } else {
                                    setStudlistarr([]);
                                    setStudscorelistarr([]);
                                    setBatchlistarr([]);
                                    getBatchlist();

                                    setStudname("");
                                    setStudenrollid("");
                                    setStudbatchname("");
                                    setStudmonthyear("");
                                    setIspdfbtn(true);
                                }
                                    
                                //     setIsselectbatch(false);
                                //     var fd=new FormData();
                                //     fd.append("livesetslno",ev.target.value);
                                //     var resp=await axios.post("https://safalata.in/safalatalara/api/liveexambatchlist",fd);
                                //     var data=resp.data;
                                // } else {
                                //     setIsselectbatch(true);
                                // }                                        
                            }}>
                            <option value="">---------SELECT MONTH YEAR---------</option>

                            {livescorelistarr.map((le)=>
                            <option key={le.studliveexamscore_year_month} value={le.studliveexamscore_year_month}>{le.studliveexamscore_year_month}</option>
                            )}

                            </select>
                        </div>
                    </div>
                    <div className="mt-4 col-4">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setStudbatchid(ev.target.value);
                                if (ev.target.value.length>0) {
                                    var fd=new FormData();
                                    fd.append('batchid',ev.target.value);
                                    var resp=await axios.post("https://safalata.in/safalatalara/api/studlistbybatchid",fd);
                                    var data=resp.data;
                                    setStudlistarr(data);

                                    setStudscorelistarr([]);
                                    setStudname("");
                                    setStudenrollid("");
                                    setStudbatchname("");
                                    setStudmonthyear("");
                                    setIspdfbtn(true);
                                } else {
                                    setStudlistarr([]);

                                    setStudscorelistarr([]);
                                    setStudname("");
                                    setStudenrollid("");
                                    setStudbatchname("");
                                    setStudmonthyear("");
                                    setIspdfbtn(true);
                                }

                            }}>
                            <option value="">---------SELECT BATCH---------</option>

                            {batchlistarr.map((e)=>
                                <option key={e.batch_slno} value={e.batch_slno}>{e.batch_name}</option>                             
                            )}

                            </select>
                        </div>
                    </div>
                    <div className="mt-4 col-4">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                
                                if (ev.target.value.length>0) {
                                    var fd=new FormData();
                                    fd.append("enrollid",ev.target.value);
                                    fd.append("scoremonthyear",scoremonthyear);
                                    var resp=await axios.post("https://safalata.in/safalatalara/api/studscorelist",fd);
                                    var data=resp.data;
                                    setStudname(data.name);
                                    setStudenrollid(data.enroll_id);
                                    setStudbatchname(data.batch_name);
                                    setStudmonthyear(data.monthyear);

                                    setStudscorelistarr(data.scorelist);

                                    setIspdfbtn(false);
                                } else {
                                    setStudscorelistarr([]);
                                    setStudname("");
                                    setStudenrollid("");
                                    setStudbatchname("");
                                    setStudmonthyear("");
                                    setIspdfbtn(true);
                                }                                        
                            }}>
                            <option value="">---------SELECT STUDENT ID---------</option>

                            {studlistarr.map((s)=>
                            <option key={s.enroll_id} value={s.enroll_id}>{s.enroll_id} - {s.name}</option>
                            )}

                            </select>
                        </div>
                    </div>
                    
                    
                </div>
                

            </div>

            

            <div className="card mb-4 mt-3" id="divToPrint" ref={inputRef}>
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    MONTH WISE SEARCH
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>STUDENT NAME</th>
                                    <th style={txtblod}>ENROLL ID</th>
                                    <th className='text-center' style={txtblod}>BATCH NAME</th>
                                    <th className='text-center' style={txtblod}>SCHEDULE</th>
                                    <th className='text-center' style={txtblod}>ACTION</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {studname?
                                <tr>
                                    <th className='text-center' style={txtblod}>{studname}</th>
                                    <th style={txtblod}>{studenrollid}</th>
                                    <th className='text-center' style={txtblod}>{studbatchname}</th>
                                    <th className='text-center' style={txtblod}>{studmonthyear}</th>
                                    {/* <th className='text-center' style={txtblod}><Link to="" onClick={printDocument} style={{textDecoration: 'none'}}>DOWNLOAD</Link></th> */}
                                    <th className='text-center' style={txtblod}><Link to="" onClick={()=>window.print()} style={{textDecoration: 'none'}}>DOWNLOAD</Link></th>
                                </tr>
                                :''}
                            </tbody>
                        
                        </table>
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>#SL</th>
                                    <th style={txtblod}>EXAM NAME</th>
                                    <th className='text-center' style={txtblod}>TOTAL QUESTION</th>
                                    <th className='text-center' style={txtblod}>TOTAL MARK</th>
                                    <th className='text-center' style={txtblod}>EXAM SCORE</th>
                                    <th className='text-center' style={txtblod}>EXAM DATE</th>
                                </tr>
                            </thead>
                            <tbody>

                            {studscorelistarr.map((e)=>
                                <tr key={e.studliveexamscore_slno}>
                                    <td className='text-center' style={txtblod}>{slno=slno+1}</td>
                                    <td style={txtblod}>{e.liveset_name}</td>
                                    <td className='text-center' style={txtblod}>{e.total_qst}</td>
                                    <td className='text-center' style={txtblod}>{e.total_mark}</td>
                                    <td className='text-center' style={txtblod}>{e.stud_liveset_score}</td>
                                    <td className='text-center' style={txtblod}>
                                        {dateFormat(e.studliveexamscore_date, "dd-mm-yyyy")}
                                    </td>                                    
                                </tr>
                            )}
                            
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}

                </div>
            </div>


            {/* // CONVERT JS TO PDF */}
            {/* <div className="mt-4 col-12 text-center">
                <input type="button" className="btn btn-primary" value="Download PDF" onClick={printDocument} disabled={ispdfbtn}/>
            </div> */}
            {/* // CONVERT JS TO PDF */}


        </div>


        </>
    );

}

export default MonthWiseLiveScoreContent;