import {Link} from 'react-router-dom';

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//import DateCountDown from 'react-date-countdown-timer';

import { useParams } from 'react-router-dom';

function ViewActiveStudentContent() {

    const params=useParams();
    
    const txtblod={
        fontWeight: '300',
        fontSize: 'medium',
        padding: 0
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };

    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconpending={
        color: '#900000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblue={
        color: '#0e007a',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [list, setList]=useState([]);
    let [count, setCount]=useState(0);

    let [loader, setLoader]=useState(true);

    let i=0;
    let j=0;

    var batchid='';

    async function getactivestudentlist() {
        var fd=new FormData();
        fd.append("batchid",params.batchid);
        var resp=await axios.post("https://safalata.in/safalatalara/api/downloadactivestudentlist",fd);
        var data=resp.data;
        setList(data.obj);
        setCount(data.datacount);

        setLoader(false);
    }
      
    useEffect(()=>{
        getactivestudentlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <div className="card mb-2 mt-2">
                <div className='row'>
                    <div className='col-sm-3 col-12'>
                        <div style={{paddingLeft:10}}><img src="../../assets/img/logo.png" style={{width: '160px'}} /></div>
                    </div>
                    <div className='col-sm-9 col-12 text-center pt-4'>
                        <h2 style={{fontSize: '1.4em', fontWeight: 700, paddingLeft:10, color: '#047000'}}>SAFALATA - Shraddha Group of Education</h2>
                        {/* <span style={{fontSize: '1.0em', fontWeight: 700, paddingLeft:10}}>Powered by cheapwebguru.com</span> */}
                        
                    </div>
                </div>
                <hr />
            </div>

            <div className="card">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i> View Active Student List
                    <br/>
                    <i className="fas fa-table me-1"></i> Total Active Student : {count}
                    <br/>
                    <Link to="/activestudent"><i className="fas fa-angle-double-left me-1"></i> BACK</Link> {count>0? <Link to="" onClick={()=>window.print()} style={{textDecoration: 'none'}}> | DOWNLOAD</Link>:''}
                </div>
                <div className="card-body" style={{marginBottom: 30}}>
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={{fontSize: 'medium', width: '10%'}}>#SlNo</th>
                                    <th style={{fontSize: 'medium', width: '25%'}}>Stud Name</th>
                                    <th className='text-center' style={{fontSize: 'medium', width: '15%'}}>Enroll ID</th>
                                    <th className='text-center' style={{fontSize: 'medium', width: '15%'}}>Batch</th>
                                    <th className='text-center' style={{fontSize: 'medium', width: '15%'}}>Mobile</th>
                                    <th className='text-left' style={{fontSize: 'medium', width: '20%'}}>City</th>
                                </tr>
                            </thead>
                        </table>

                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <tbody>

                            {list.map((e,i)=>

                            <tr key={e.liveset_slno}>
                                
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '10%'}}>{++i}</td>
                                <td style={{fontSize: 'medium', width: '25%', fontWeight: '700'}}>{e.name}</td>
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '15%'}}>{e.enroll_id}</td>
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '15%'}}>{e.batch_name}</td>
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '15%'}}>{e.phone}</td>
                                <td className='text-left' style={{fontSize: 'medium', fontWeight: '700', width: '20%'}}>{e.city}</td>
                            </tr>    

                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

        


        </div>

        </>
    );

}

export default ViewActiveStudentContent;